@mixin centerelm {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin greenButton {
  height: 5.5rem;
  background: $ctaGradient;
  border-radius: 1.2rem;
  font-size: $ctaText;
  color: $white-color;
  font-weight: $fw-600;
  @include x-767-tablet {
    height: 4.5rem;
    font-size: 1.6rem;
  }
  &:hover {
    background: $ctaGradient-dark;
  }
}

@mixin greenAnchorBtn {
  .btn {
    &.greenBtn {
      @include centerelm;
      @include greenButton;
      background: $ctaGradient;
      gap: 1.5rem;
      .fa-phone {
        display: none;
      }
      span {
        color: $white-color;
        font-size: $ctaText;
        @include x-767-tablet {
          font-size: 1.6rem;
        }
      }
      &:hover {
        background: $ctaGradient-dark;
      }
    }
  }
}

@mixin form {
  .formWrap {
    background-color: $white-color;
    border-radius: 1.2rem;
    padding: 1rem 3rem;
    border: 0.1rem solid #ccc;
    .formHeaderSection {
      @include centerelm;
      flex-direction: column;
      .formImgWrap {
        border-radius: 50%;
        max-width: 80px;
        overflow: hidden;
        position: relative;
        vertical-align: middle;
      }
      .formData {
        margin-top: 1.8rem;
        h5 {
          font-size: 2.6rem;
          font-weight: $fw-500;
          color: $black-color;
          text-align: center;
          margin-bottom: 1.5rem;
          @include x-767-tablet{
            font-size: 2rem;
          }
        }
        p {
          color: $light-black-color;
          font-size: 1.9rem;
          margin-bottom: 0.6rem;
          @include x-767-tablet{
            font-size: 1.6rem;
            text-align: center;
          }
        }
        .btn {
          padding: 1.3rem 2.9rem;
          display: flex;
          justify-content: center;
          gap: 1.5rem;
          font-size: $ctaText;
          color: $orange-color;
          font-size: 1.8rem;
          font-weight: $fw-600;
          @include x-767-tablet{
            font-size: 1.6rem;
          }
          .telPhoneIcon {
            .fa-phone {
              font-size: 2rem;
              @include x-767-tablet{
                font-size: 1.6rem;
              }
            }
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    .formInputWrap {
      padding-top: 3rem;
      label {
        font-size: 2rem;
        font-weight: $fw-400;
        color: $black-color;
        margin-bottom: 1rem;
      }
      .inputBox {
        width: 100%;
        height: 5.5rem;
        border: 0.1rem solid #d2d2d2;
        border-radius: 1rem;
        padding: 0 1rem;
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }

      .formBtn {
        @include greenButton;
      }
      .formText {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 0;
        color: $light-black-color;
        font-size: 1.3rem;
        line-height: 24px;
        font-weight: $fw-500;
      }
    }
  }
}

@mixin listing {
  .ulLsit {
    padding-left: 0;
    margin-top: 3rem;
    margin-bottom: 0;
    li {
      @include centerelm;
      justify-content: flex-start;
      gap: 1rem;
      font-size: $ctaText;
      color: $black-color;
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        svg {
          height: 1.8rem;
          path {
            fill: $orange-color;
          }
        }
      }
    }
  }
}

@mixin accordion {
  .accordionWrap {
    .accordion {
      width: 100%;
      .accordion-item {
        background-color: transparent;
        border: 0;
        overflow: hidden;
        transition: height 0.3s ease;
        height: 7rem;
        &:hover {
          background-color: #fff;
          .accordion-title {
            color: $orange-color;
          }
        }
        &.open {
          height: 24rem;
          &:hover {
            background-color: #fff;
          }
        }
        .accordion-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 2rem 2.5rem;
          font-size: 2.2rem;
          font-weight: $fw-500;
          color: $black-color;
          cursor: pointer;
          .icon {
            font-size: 1.8rem;
            color: $orange-color;
          }
        }
        .accordion-content {
          padding: 1.5rem 2.5rem;
          font-size: 2rem;
          font-weight: $fw-400;
          color: $black-color;
        }
      }
    }
  }
}
