@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Nunito:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.33%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
  position: relative;
  font-family: "DM Sans", Arial, Helvetica, sans-serif;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: block;
  transition: top 0.3s;
  padding: 1.5rem 0;
  z-index: 1000;
}
@media (max-width: 767px) {
  #header {
    width: 100vw;
  }
}
#header #navigationBar .navbar-brand img {
  max-width: 70%;
}
@media (max-width: 834px) {
  #header #navigationBar .navbar-brand img {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  #header #navigationBar .navbar-brand img {
    max-width: 50%;
  }
}
@media (max-width: 991px) {
  #header #navigationBar #basic-navbar-nav #deskMenu {
    display: none;
  }
}
@media (min-width: 992px) {
  #header #navigationBar #basic-navbar-nav #mobMenu {
    display: none;
  }
}
@media (max-width: 834px) {
  #header #navigationBar #basic-navbar-nav .navbar-nav.pe-5 {
    padding-right: 0 !important;
  }
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down {
  position: relative;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus {
  border-radius: 1.2rem;
  position: absolute;
  width: 115rem;
  z-index: 10;
  left: -555%;
  transform: translateY(0);
  background: #fff;
  padding: 3rem;
  border: 0.1rem solid rgba(119, 119, 119, 0.1215686275);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.3s;
}
@media (max-width: 834px) {
  #header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .row {
    row-gap: 4rem;
  }
  #header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .row .col-md-4 {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  #header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus {
    width: 95rem;
    left: -415%;
  }
}
@media (max-width: 834px) {
  #header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus {
    width: 100%;
    left: 0;
  }
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus:hover .drop_Down {
  display: none;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li {
  margin-bottom: 3rem;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li:last-child {
  margin-bottom: 0;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li .drop_menu_link {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li .drop_menu_link:hover .menu_name {
  color: #f86011;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li .drop_menu_link .menueWrap {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li .drop_menu_link .menueWrap .menuTextWrap {
  display: flex;
  flex-direction: column;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li .drop_menu_link .menu_name {
  font-size: 1.8rem;
  font-weight: 600;
  color: #000000;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .drop_down_menus .drop_menu_innerBox ul li .drop_menu_link .menu_desc {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down:hover .drop_down_menus {
  opacity: 1;
  transform: translateY(0);
  display: block;
  pointer-events: all;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down:hover .nav-link {
  color: #f86011;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.1rem;
  background-color: #f86011;
  transition: width 0.3s;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down:hover.active::after {
  display: none;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down:hover::after {
  width: 100%;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .nav-link {
  font-size: 1.8rem;
  font-weight: 600;
  color: #000000;
  position: relative;
  display: flex;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .nav-link.active {
  color: #f86011;
  border-bottom: 0.2rem solid #f86011;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .nav-link.dropdown-toggle {
  position: relative;
}
#header #navigationBar #basic-navbar-nav .navbar-nav .drop_Down .nav-link.dropdown-toggle::after {
  display: none;
}
@media (max-width: 991px) {
  #header #navigationBar #basic-navbar-nav.navbar-collapse {
    background: #fff;
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    display: block;
    height: 100%;
    left: -100%;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    position: fixed;
    top: 0;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
    width: 50%;
    z-index: 999999;
  }
}
@media (max-width: 991px) {
  #header #navigationBar #basic-navbar-nav.navbar-collapse {
    width: 80%;
  }
}
#header #navigationBar #basic-navbar-nav.navbar-collapse.show {
  left: 0;
}
#header #navigationBar #basic-navbar-nav.navbar-collapse.show .navbar-nav .nav-item {
  border-bottom: 1px solid #fff;
  padding-bottom: 1.5rem;
}
#header #navigationBar #basic-navbar-nav.navbar-collapse.show .navbar-nav .nav-item:last-child {
  border-bottom: 0;
}
#header #navigationBar #basic-navbar-nav.navbar-collapse.show .navbar-nav .nav-item .nav-link {
  justify-content: space-between;
  font-size: 2rem;
  color: unset;
  position: relative;
}
#header #navigationBar #basic-navbar-nav.navbar-collapse.show .navbar-nav .nav-item .nav-link:hover {
  color: #2276b9;
}
@media (max-width: 991px) {
  #header #navigationBar #basic-navbar-nav.navbar-collapse.show .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
  }
}
@media (max-width: 991px) {
  #header #navigationBar #basic-navbar-nav.navbar-collapse.show .navbar-nav .nav-item .nav-link {
    color: #fff;
  }
}

.navNumber {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 600;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.navNumber i {
  color: #f86011;
  font-size: 2.2rem;
}

.getQuote .btn.orgBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: #feefe7;
  gap: 1.5rem;
  padding: 0 4rem;
}
@media (max-width: 767px) {
  .getQuote .btn.orgBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.getQuote .btn.orgBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.getQuote .btn.orgBtn i {
  display: none;
}
.getQuote .btn.orgBtn span {
  color: #f86011;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .getQuote .btn.orgBtn span {
    font-size: 1.6rem;
  }
}
.getQuote .btn.orgBtn:hover {
  background: #f86011;
}
.getQuote .btn.orgBtn:hover span {
  color: #fff;
}

#banner_section {
  background-image: url(../images/banner/cyber-security-services.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 70rem;
  margin-top: 8rem;
  padding-top: 10rem;
}
@media (max-width: 767px) {
  #banner_section {
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
#banner_section .formRow {
  justify-content: space-between;
}
#banner_section .sectionTitle {
  color: #fff;
  font-size: 7.2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  #banner_section .sectionTitle {
    font-size: 4.2rem;
    text-align: center;
  }
}
#banner_section .sectionTitle .svgWrap {
  position: relative;
  display: inline-block;
}
#banner_section .sectionTitle .svgWrap .svgBox {
  position: relative;
  display: inline-block;
}
#banner_section .sectionTitle .svgWrap svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 0.75em);
  height: calc(100% + 0.75em);
  transform: translate(-50%, -50%);
  overflow: visible;
  margin-top: -0.8rem;
}
#banner_section .sectionTitle .svgWrap svg path {
  stroke: #00a551;
  fill: none;
  stroke-width: 8;
  stroke-dasharray: 1500;
  stroke-linecap: round;
  stroke-linejoin: round;
  animation: svgAnimation 10s linear infinite;
}
@keyframes svgAnimation {
  0% {
    stroke-dasharray: 0 1500;
    opacity: 1;
  }
  15% {
    stroke-dasharray: 1500 1500;
  }
  85% {
    opacity: 1;
  }
  90% {
    stroke-dasharray: 1500 1500;
    opacity: 0;
  }
  100% {
    stroke-dasharray: 0 1500;
    opacity: 0;
  }
}
#banner_section .sectionPara {
  font-size: 3rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 3rem;
  line-height: 1.5;
}
@media (max-width: 767px) {
  #banner_section .sectionPara {
    text-align: center;
    font-size: 2.4rem;
    padding: 0 1.5rem;
  }
}
@media (max-width: 767px) {
  #banner_section .ctaWrap {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
}
#banner_section .ctaWrap .btn {
  background-color: #fff;
  border: 0.2rem solid #000000;
  padding: 1.3rem 2.9rem;
  border-radius: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
#banner_section .ctaWrap .btn .btnText {
  font-size: 1.8rem;
  color: #000000;
  font-weight: 500;
  padding-right: 1rem;
}
#banner_section .ctaWrap .btn .material-symbols-outlined {
  color: #000000;
  font-size: 2.3rem;
}
#banner_section .ctaWrap .btn:hover {
  background-color: transparent;
  border: 0.2rem solid #fff;
}
#banner_section .ctaWrap .btn:hover .btnText {
  color: #fff;
}
#banner_section .ctaWrap .btn:hover .material-symbols-outlined {
  color: #fff;
}
#banner_section .formWrap {
  background-color: #fff;
  border-radius: 1.2rem;
  padding: 1rem 3rem;
  border: 0.1rem solid #ccc;
}
#banner_section .formWrap .formHeaderSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#banner_section .formWrap .formHeaderSection .formImgWrap {
  border-radius: 50%;
  max-width: 80px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
#banner_section .formWrap .formHeaderSection .formData {
  margin-top: 1.8rem;
}
#banner_section .formWrap .formHeaderSection .formData h5 {
  font-size: 2.6rem;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
  #banner_section .formWrap .formHeaderSection .formData h5 {
    font-size: 2rem;
  }
}
#banner_section .formWrap .formHeaderSection .formData p {
  color: #777777;
  font-size: 1.9rem;
  margin-bottom: 0.6rem;
}
@media (max-width: 767px) {
  #banner_section .formWrap .formHeaderSection .formData p {
    font-size: 1.6rem;
    text-align: center;
  }
}
#banner_section .formWrap .formHeaderSection .formData .btn {
  padding: 1.3rem 2.9rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.8rem;
  color: #f86011;
  font-size: 1.8rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  #banner_section .formWrap .formHeaderSection .formData .btn {
    font-size: 1.6rem;
  }
}
#banner_section .formWrap .formHeaderSection .formData .btn .telPhoneIcon .fa-phone {
  font-size: 2rem;
}
@media (max-width: 767px) {
  #banner_section .formWrap .formHeaderSection .formData .btn .telPhoneIcon .fa-phone {
    font-size: 1.6rem;
  }
}
#banner_section .formWrap .formHeaderSection .formData .btn:focus {
  outline: none;
  box-shadow: none;
}
#banner_section .formWrap .formInputWrap {
  padding-top: 3rem;
}
#banner_section .formWrap .formInputWrap label {
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 1rem;
}
#banner_section .formWrap .formInputWrap .inputBox {
  width: 100%;
  height: 5.5rem;
  border: 0.1rem solid #d2d2d2;
  border-radius: 1rem;
  padding: 0 1rem;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
#banner_section .formWrap .formInputWrap .formBtn {
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 767px) {
  #banner_section .formWrap .formInputWrap .formBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
#banner_section .formWrap .formInputWrap .formBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
#banner_section .formWrap .formInputWrap .formText {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  color: #777777;
  font-size: 1.3rem;
  line-height: 24px;
  font-weight: 500;
}

.pad_lr {
  padding: 0 2.5rem;
}
@media (max-width: 834px) {
  .pad_lr {
    padding: 0;
  }
}

#consultationSection {
  padding: 15rem 0 0;
}
@media (max-width: 767px) {
  #consultationSection {
    padding: 5rem 1.5rem 0;
  }
}
#consultationSection .row {
  justify-content: space-evenly;
  margin-bottom: 10rem;
}
@media (max-width: 767px) {
  #consultationSection .row {
    margin-bottom: 2rem;
  }
}
#consultationSection .row:nth-child(even) {
  flex-direction: row-reverse;
}
@media (max-width: 1024px) {
  #consultationSection .row .col-md-4 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  #consultationSection .row .col-md-4 {
    width: 100% !important;
  }
}
#consultationSection .row .right_anime_box h6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  font-size: 2rem;
  font-weight: 500;
  color: #000000;
  line-height: 3rem;
}
#consultationSection .row .right_anime_box h6 span {
  display: block;
  width: 4.4rem;
  height: 4.4rem;
}
#consultationSection .row .right_anime_box h3 {
  font-size: 5.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  #consultationSection .row .right_anime_box h3 {
    font-size: 3.4rem;
    margin-bottom: 2rem;
  }
}
#consultationSection .row .right_anime_box p {
  font-size: 2.4rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  #consultationSection .row .right_anime_box p {
    font-size: 2rem;
  }
}
#consultationSection .row .right_anime_box .consultBtn {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 767px) {
  #consultationSection .row .right_anime_box .consultBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
#consultationSection .row .right_anime_box .consultBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
@media (max-width: 1024px) {
  #consultationSection .row .right_anime_box .consultBtn {
    width: 75% !important;
  }
}
@media (max-width: 767px) {
  #consultationSection .row .right_anime_box .consultBtn {
    width: 75% !important;
  }
}

#ourClients {
  padding: 2rem 0 10rem;
}
@media (max-width: 767px) {
  #ourClients {
    padding: 2rem 0 3rem;
  }
}
#ourClients .clientWrap .sectionTitle {
  font-size: 5.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
@media (max-width: 767px) {
  #ourClients .clientWrap .sectionTitle {
    font-size: 3.4rem;
  }
}
#ourClients .clientWrap p {
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  width: 75%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  #ourClients .clientWrap p {
    width: 85%;
  }
}
#ourClients .clientWrap .client-logo-listing {
  height: 135px;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 3rem;
}
#ourClients .clientWrap .client-logo-listing .brands-marquee {
  padding: 25px 0;
}
#ourClients .clientWrap .client-logo-listing .js-marquee-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  animation: 140s linear 0s infinite normal none running marqueeAnimation-3709221;
  margin-left: 0px;
  width: 18395px;
  transform: translateX(0px);
}
#ourClients .clientWrap .client-logo-listing .js-marquee-wrapper:hover {
  animation: 140s linear 0s infinite normal none paused marqueeAnimation-3709221 !important;
}
#ourClients .clientWrap .client-logo-listing ul {
  display: flex;
  align-items: center;
  height: 100%;
}
#ourClients .clientWrap .client-logo-listing ul li {
  padding: 0px 50px;
  display: block;
}
#ourClients .clientWrap .svg-sprite {
  background-image: url(../images/clients/client-logos-sprites.png);
  background-repeat: no-repeat;
  display: block;
  height: 100px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-oracle {
  background-position: 0px 0px;
  width: 170px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-mtn {
  background-position: -780px 0px;
  width: 205px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-sanchobbdo {
  background-position: -1355px 0px;
  width: 190px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-gulfstream {
  background-position: -1620px 0px;
  width: 260px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-daman {
  background-position: -1955px 0px;
  width: 250px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-astrazeneca {
  background-position: -2280px 0px;
  width: 290px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-vfsglobal {
  background-position: -2645px 0px;
  width: 205px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-haas {
  background-position: -2935px 0px;
  width: 200px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-tata {
  background-position: -3225px 0px;
  width: 175px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-yahoo {
  background-position: -3490px 0px;
  width: 170px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-smithfield {
  background-position: -3745px 0px;
  width: 100px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-best-buy {
  background-position: -3920px 0px;
  width: 80px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-essilor {
  background-position: -4085px 0px;
  width: 175px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-cosmopolitan {
  background-position: -4340px 0px;
  width: 110px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-abbott {
  background-position: -4535px 0px;
  width: 170px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-unsw {
  background-position: 0px -85px;
  width: 175px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-lifetechnologies {
  background-position: -220px -85px;
  width: 70px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-jacksoncoker {
  background-position: -385px -95px;
  width: 115px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-mcdonalds {
  background-position: -595px -85px;
  width: 200px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-vodafone {
  background-position: -885px -85px;
  width: 65px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-adidas {
  background-position: -1045px -85px;
  width: 185px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-ndtv {
  background-position: -1320px -85px;
  width: 150px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-kotak {
  background-position: -1560px -75px;
  width: 240px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-macys {
  background-position: -1890px -80px;
  width: 115px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-cartier {
  background-position: -2100px -85px;
  width: 185px;
}
#ourClients .clientWrap .svg-sprite.svg-sprite.logo-cambridge-v3 {
  background-position: -260px 0;
  width: 160px;
}
#ourClients .clientWrap .svg-sprite.abdul-latif-jameel-logo {
  background-position: -515px 0;
  width: 180px;
}
#ourClients .clientWrap .svg-sprite.abdul-latif-jameel-logo-001 {
  background-position: -1077px 0;
  width: 200px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo1 {
  background-position: -2365px -85px;
  width: 80px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo2 {
  background-position: -2535px -85px;
  width: 110px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo3 {
  background-position: -2730px -85px;
  width: 125px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo4 {
  background-position: -2940px -90px;
  width: 165px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo5 {
  background-position: -3190px -90px;
  width: 135px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo6 {
  background-position: -3420px -90px;
  width: 225px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo7 {
  background-position: -3730px -95px;
  width: 190px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo8 {
  background-position: -4020px -95px;
  width: 110px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo9 {
  background-position: -4230px -95px;
  width: 180px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo10 {
  background-position: -4510px -90px;
  width: 215px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo11 {
  background-position: 0px -185px;
  width: 200px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo12 {
  background-position: -295px -185px;
  width: 200px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo13 {
  background-position: -555px -170px;
  width: 135px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo14 {
  background-position: -765px -170px;
  width: 225px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo15 {
  background-position: -1075px -175px;
  width: 185px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo16 {
  background-position: -1335px -175px;
  width: 165px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo17 {
  background-position: -1575px -175px;
  width: 260px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo18 {
  background-position: -1910px -175px;
  width: 245px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo19 {
  background-position: -2215px -175px;
  width: 220px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo20 {
  background-position: -2510px -175px;
  width: 110px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo21 {
  background-position: -2700px -175px;
  width: 70px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo22 {
  background-position: -2860px -175px;
  width: 150px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo23 {
  background-position: -3070px -175px;
  width: 255px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo24 {
  background-position: -3400px -175px;
  width: 90px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo25 {
  background-position: -3580px -175px;
  width: 75px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo26 {
  background-position: -3735px -175px;
  width: 245px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo27 {
  background-position: -4065px -175px;
  width: 265px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo28 {
  background-position: -4390px -175px;
  width: 290px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo29 {
  background-position: 0px -265px;
  width: 250px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo30 {
  background-position: -325px -265px;
  width: 235px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo31 {
  background-position: -630px -265px;
  width: 145px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo32 {
  background-position: -855px -265px;
  width: 150px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo33 {
  background-position: -1075px -265px;
  width: 170px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo34 {
  background-position: -1320px -265px;
  width: 140px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo35 {
  background-position: -1540px -265px;
  width: 130px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo36 {
  background-position: -1750px -265px;
  width: 225px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo37 {
  background-position: -2035px -265px;
  width: 180px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo38 {
  background-position: -2295px -265px;
  width: 200px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo39 {
  background-position: -2580px -265px;
  width: 75px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo40 {
  background-position: -2750px -265px;
  width: 80px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo41 {
  background-position: -2900px -265px;
  width: 135px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo42 {
  background-position: -3120px -265px;
  width: 180px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo43 {
  background-position: -3370px -265px;
  width: 265px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo44 {
  background-position: -3705px -265px;
  width: 150px;
}
#ourClients .clientWrap .svg-sprite.logo-brand-black-Logo45 {
  background-position: -3935px -265px;
  width: 125px;
}
@keyframes marqueeAnimation-3709221 {
  100% {
    transform: translateX(-100%);
  }
}

#stickyHeader.sticky {
  position: sticky;
  top: 0.3%;
  left: 0;
  width: 100%;
  z-index: 99;
}
@media (max-width: 834px) {
  #stickyHeader.sticky {
    position: relative;
  }
}
#stickyHeader .stickyBoxWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 4rem;
  border-color: #efefef;
  border-width: 0 0.1rem 0.1rem 0.1rem;
  border-style: solid;
  border-radius: 2rem;
  background-color: #fff;
}
@media (max-width: 834px) {
  #stickyHeader .stickyBoxWrap {
    padding: 2rem 2rem;
  }
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap {
    flex-direction: column;
    gap: 4rem;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_8 {
  width: 8%;
}
@media (max-width: 834px) {
  #stickyHeader .stickyBoxWrap .custom_width_8 {
    width: 12%;
  }
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_8 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_8 .profileImgWrap {
  border-radius: 50%;
  max-width: 6.2rem;
  overflow: hidden;
}
#stickyHeader .stickyBoxWrap .custom_width_20 {
  width: 20%;
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_20 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_20 .profileDataWrap {
    text-align: center;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_20 .profileDataWrap h6 {
  font-size: 2rem;
  color: #000000;
  font-weight: 500;
}
#stickyHeader .stickyBoxWrap .custom_width_20 .profileDataWrap p {
  font-size: 1.4rem;
  color: #000000;
  line-height: 2.4rem;
  margin-bottom: 0;
}
#stickyHeader .stickyBoxWrap .custom_width_46 {
  width: 46%;
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_46 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_46 .barTitle h5 {
  font-size: 2.6rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_46 .barTitle h5 {
    font-size: 2rem;
    text-align: center;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_26 {
  width: 26%;
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_26 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_26 .barBtn {
    width: 100%;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: #feefe7;
  gap: 1.5rem;
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
#stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn .fa-phone {
  color: #f86011;
  font-size: 2.4rem;
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn .fa-phone {
    font-size: 1.6rem;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn span {
  color: #f86011;
  font-size: 1.8rem;
}
@media (max-width: 834px) {
  #stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn span {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  #stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn span {
    font-size: 1.6rem;
  }
}
#stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn:hover .fa-phone {
  color: #fff;
}
#stickyHeader .stickyBoxWrap .custom_width_26 .barBtn .btn.orgBtn:hover span {
  color: #fff;
}

#organisationSection {
  padding: 14rem 0 10rem;
}
@media (max-width: 834px) {
  #organisationSection {
    padding: 10rem 0 10rem;
  }
}
@media (max-width: 767px) {
  #organisationSection {
    padding: 0rem 0 6rem;
  }
}
#organisationSection .sectionTitle h3 {
  font-size: 5.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 8rem;
  text-align: center;
}
@media (max-width: 767px) {
  #organisationSection .sectionTitle h3 {
    font-size: 3.4rem;
    margin-bottom: 3rem;
  }
}
#organisationSection .sectionTitle.orgOtherPage {
  margin-bottom: 8rem;
}
@media (max-width: 767px) {
  #organisationSection .sectionTitle.orgOtherPage {
    padding: 0 2rem;
    margin-bottom: 3rem;
  }
}
#organisationSection .sectionTitle.orgOtherPage .row {
  justify-content: space-between;
}
@media (max-width: 1024px) {
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-6 {
    width: 60%;
  }
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-3 {
    width: 35%;
  }
}
@media (max-width: 834px) {
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-6 {
    width: 100%;
  }
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-6 h3 {
    text-align: center;
  }
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-3 {
    width: 100%;
  }
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-3 .readMoreBtnWrap .btn.greenBtn {
    width: 40%;
    margin: 0 auto;
  }
}
@media (max-width: 834px) and (max-width: 767px) {
  #organisationSection .sectionTitle.orgOtherPage .row .col-md-3 .readMoreBtnWrap .btn.greenBtn {
    width: 85%;
  }
}
#organisationSection .sectionTitle.orgOtherPage h3 {
  text-align: left;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  #organisationSection .sectionTitle.orgOtherPage h3 {
    text-align: center;
  }
}
#organisationSection .sectionTitle.orgOtherPage .readMoreBtnWrap {
  width: 100%;
}
#organisationSection .orgCard {
  width: 33.3333333333%;
  padding: 0;
}
@media (max-width: 834px) {
  #organisationSection .orgCard {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #organisationSection .orgCard {
    width: 100%;
  }
}
#organisationSection .orgCard .innerOrgCard {
  padding: 2.4rem;
}
#organisationSection .orgCard .innerOrgCard .orgImgWrap {
  overflow: hidden;
  width: 100%;
  margin-bottom: 3rem;
  position: relative;
}
#organisationSection .orgCard .innerOrgCard .orgImgWrap a:hover .overlay {
  position: absolute;
  width: 100%;
  height: 140%;
  z-index: 1;
  top: 3rem;
  left: 0;
  background: linear-gradient(to top, #f86212 0, rgba(248, 17, 109, 0) 100%);
}
#organisationSection .orgCard .innerOrgCard .orgLink h3 {
  font-size: 3.1rem;
  font-weight: 500;
  color: #000000;
  line-height: 1.3;
  padding-bottom: 2rem;
  border-bottom: 0.2rem dotted #eaeaea;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  #organisationSection .orgCard .innerOrgCard .orgLink h3 {
    font-size: 2.6rem;
  }
}
#organisationSection .orgCard .innerOrgCard .orgLink:hover h3 {
  color: #00a551;
}
#organisationSection .orgCard .innerOrgCard h4 {
  font-size: 5.4rem;
  font-weight: 700;
  color: #000000;
  padding-top: 2rem;
  margin-bottom: 0rem;
}
@media (max-width: 767px) {
  #organisationSection .orgCard .innerOrgCard h4 {
    font-size: 3.4rem;
  }
}
#organisationSection .orgCard .innerOrgCard p {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 0;
}
#organisationSection .readMoreBtnWrap {
  width: 22%;
  margin: 6rem auto 0;
}
@media (max-width: 1024px) {
  #organisationSection .readMoreBtnWrap {
    width: 35%;
  }
}
#organisationSection .readMoreBtnWrap .btn.greenBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  gap: 1.5rem;
}
@media (max-width: 767px) {
  #organisationSection .readMoreBtnWrap .btn.greenBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
#organisationSection .readMoreBtnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
#organisationSection .readMoreBtnWrap .btn.greenBtn .fa-phone {
  display: none;
}
#organisationSection .readMoreBtnWrap .btn.greenBtn span {
  color: #fff;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  #organisationSection .readMoreBtnWrap .btn.greenBtn span {
    font-size: 1.6rem;
  }
}
#organisationSection .readMoreBtnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
@media (max-width: 834px) {
  #organisationSection .readMoreBtnWrap {
    width: 40%;
  }
}
@media (max-width: 767px) {
  #organisationSection .readMoreBtnWrap {
    width: 85%;
    margin: 2rem auto 0;
  }
}

.darkBG {
  background: linear-gradient(270deg, #f4f4f6 0%, #fff 100%);
  padding: 12rem 0;
}

.darkBG .featureWrap {
  padding: 10rem 0 0;
}
@media (max-width: 767px) {
  .darkBG .featureWrap {
    padding: 0rem 0 0;
    margin-top: -10rem;
  }
}
.darkBG .featureWrap .row {
  row-gap: 7rem;
}
@media (max-width: 767px) {
  .darkBG .featureWrap .row {
    row-gap: 3rem;
  }
}
.darkBG .featureWrap .featBoxWrap {
  display: flex;
  gap: 2rem;
  justify-content: center;
}
.darkBG .featureWrap .featBoxWrap .iconWrap {
  width: 7rem;
  height: 7rem;
  border: 0.2rem solid #f86011;
  border-radius: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.darkBG .featureWrap .featBoxWrap .iconWrap img {
  width: 45%;
}
.darkBG .featureWrap .featBoxWrap .featContentBox {
  width: 70%;
}
.darkBG .featureWrap .featBoxWrap .featContentBox h5 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #000000;
}
@media (max-width: 767px) {
  .darkBG .featureWrap .featBoxWrap .featContentBox h5 {
    font-size: 2.2rem;
  }
}
.darkBG .featureWrap .featBoxWrap .featContentBox p {
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0;
}
.darkBG .awardSection {
  padding-top: 12rem;
}
@media (max-width: 767px) {
  .darkBG .awardSection {
    padding-top: 6rem;
  }
}
.darkBG .awardSection h5 {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 3.5rem;
  color: #000000;
  text-align: center;
}
@media (max-width: 767px) {
  .darkBG .awardSection h5 {
    font-size: 2.8rem;
  }
}
.darkBG .awardSection .awardBox {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 767px) {
  .darkBG .awardSection .awardBox {
    margin-bottom: 3rem;
  }
}
.darkBG .awardSection .awardBox .awardImgWrap {
  text-align: center;
  max-width: 15rem;
  margin-bottom: 0.5rem;
}
@media (max-width: 767px) {
  .darkBG .awardSection .awardBox .awardImgWrap {
    max-width: 10rem;
  }
}
.darkBG .awardSection .awardBox .awardImgWrap img {
  transition: filter 0.3s ease;
}
.darkBG .awardSection .awardBox .awardImgWrap img#img1 {
  filter: hue-rotate(172deg) saturate(106%);
}
.darkBG .awardSection .awardBox .awardImgWrap img#img2 {
  filter: saturate(116%) brightness(106%) contrast(106%);
}
.darkBG .awardSection .awardBox .awardImgWrap img#img3 {
  filter: hue-rotate(336deg) contrast(116%);
}
.darkBG .awardSection .awardBox .awardImgWrap img#img4 {
  filter: hue-rotate(216deg) saturate(124%) brightness(104%) contrast(106%);
}
.darkBG .awardSection .awardBox .awardImgWrap img:hover#img1 {
  filter: hue-rotate(0deg) saturate(100%);
}
.darkBG .awardSection .awardBox .awardImgWrap img:hover#img2 {
  filter: saturate(100%) brightness(100%) contrast(100%);
}
.darkBG .awardSection .awardBox .awardImgWrap img:hover#img3 {
  filter: hue-rotate(0deg) contrast(100%);
}
.darkBG .awardSection .awardBox .awardImgWrap img:hover#img4 {
  filter: hue-rotate(0deg) saturate(100%) brightness(100%) contrast(100%);
}
.darkBG .awardSection .awardBox .awardNameWrap p {
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .darkBG .awardSection .awardBox .awardNameWrap p {
    font-size: 1.6rem;
  }
}

.leftInner {
  padding: 0 6rem;
}
@media (max-width: 767px) {
  .leftInner {
    padding: 0 1.5rem;
  }
}
.leftInner .sectionTitle {
  font-size: 5.4rem;
  font-weight: 500;
  margin-bottom: 3rem;
  text-align: left;
}
@media (max-width: 767px) {
  .leftInner .sectionTitle {
    font-size: 3.4rem;
  }
}
.leftInner .sectionPara {
  font-size: 2.4rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 3rem;
}
.leftInner .abstractorName {
  display: none;
}
.leftInner .btnWrap {
  width: 45%;
}
.leftInner .btnWrap .btn.greenBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  gap: 1.5rem;
}
@media (max-width: 767px) {
  .leftInner .btnWrap .btn.greenBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.leftInner .btnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.leftInner .btnWrap .btn.greenBtn .fa-phone {
  display: none;
}
.leftInner .btnWrap .btn.greenBtn span {
  color: #fff;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .leftInner .btnWrap .btn.greenBtn span {
    font-size: 1.6rem;
  }
}
.leftInner .btnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
@media (max-width: 1024px) {
  .leftInner .btnWrap {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .leftInner .btnWrap {
    width: 65%;
    margin-bottom: 4rem;
  }
}

.rightInner .imgWraps {
  display: inline-block;
  z-index: 1;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  max-width: 100%;
  line-height: 1;
  border-radius: 0.8rem;
  height: 50rem;
}

.abstractSection {
  padding: 10rem 0 10rem;
}
@media (max-width: 767px) {
  .abstractSection {
    padding: 0rem 0 3rem;
  }
}
.abstractSection .leftInner {
  padding: 0 11rem;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  .abstractSection .leftInner {
    padding: 0 3rem;
  }
}
@media (max-width: 767px) {
  .abstractSection .leftInner {
    padding: 0 1.5rem;
    margin-top: -10rem;
  }
}
.abstractSection .leftInner .sectionTitle {
  display: none;
}
.abstractSection .leftInner .sectionPara {
  font-size: 3.2rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .abstractSection .leftInner .sectionPara {
    font-size: 2.2rem;
  }
}
.abstractSection .leftInner .abstractorName {
  display: block;
}
.abstractSection .leftInner .abstractorName h5 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}
.abstractSection .leftInner .abstractorName p {
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 0;
}
.abstractSection .leftInner .btnWrap {
  display: none;
}

.adviceSection .titleRow {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .adviceSection .titleRow {
    padding: 0 1rem;
  }
}
.adviceSection .titleRow .sectionTitle h5 {
  font-size: 5.4rem;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 1.14;
}
@media (max-width: 767px) {
  .adviceSection .titleRow .sectionTitle h5 {
    font-size: 3.4rem;
    line-height: 1.44;
    margin-bottom: 3rem;
  }
}
.adviceSection .titleRow .btnBox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
}
.adviceSection .titleRow .btnBox .btn.greenBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  gap: 1.5rem;
}
@media (max-width: 767px) {
  .adviceSection .titleRow .btnBox .btn.greenBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.adviceSection .titleRow .btnBox .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.adviceSection .titleRow .btnBox .btn.greenBtn .fa-phone {
  display: none;
}
.adviceSection .titleRow .btnBox .btn.greenBtn span {
  color: #fff;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .adviceSection .titleRow .btnBox .btn.greenBtn span {
    font-size: 1.6rem;
  }
}
.adviceSection .titleRow .btnBox .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.adviceSection .titleRow .btnBox .btn.greenBtn {
  gap: 0;
  width: 90%;
}
@media (max-width: 1024px) {
  .adviceSection .titleRow .btnBox .btn.greenBtn {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .adviceSection .titleRow .btnBox .btn.greenBtn {
    width: 65%;
  }
}
.adviceSection .adviceCardsSection {
  padding-top: 8rem;
}
@media (max-width: 767px) {
  .adviceSection .adviceCardsSection .outerCardWrap {
    margin-bottom: 4rem;
  }
}
.adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap {
  padding: 0 1.5rem;
}
.adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap .cardImageWrap {
  position: relative;
  overflow: hidden;
}
.adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap .cardImageWrap a:hover .overlay {
  position: absolute;
  width: 100%;
  height: 140%;
  z-index: 1;
  top: 3rem;
  left: 0;
  background: linear-gradient(to top, #f86212 0, rgba(248, 17, 109, 0) 100%);
}
.adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap .cardTitleWrap {
  margin-top: 2.5rem;
}
.adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap .cardTitleWrap a h3 {
  font-size: 3.2rem;
  color: #000000;
}
@media (max-width: 767px) {
  .adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap .cardTitleWrap a h3 {
    font-size: 2.8rem;
  }
}
.adviceSection .adviceCardsSection .outerCardWrap .innerCardWrap .cardTitleWrap a:hover h3 {
  color: #00a551;
}

.fooFormSection {
  padding: 10rem 0;
  background-color: #000000;
}
@media (max-width: 767px) {
  .fooFormSection {
    padding: 4rem 0;
  }
}
.fooFormSection .row {
  justify-content: space-between;
}
.fooFormSection .row .leftBoxWrap {
  padding-right: 5rem;
}
@media (max-width: 834px) {
  .fooFormSection .row .leftBoxWrap {
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .fooFormSection .row .leftBoxWrap {
    padding: 0 2rem;
  }
}
.fooFormSection .row .leftBoxWrap .sectionTitle {
  font-size: 7.2rem;
  color: #fff;
  line-height: 1.2;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .fooFormSection .row .leftBoxWrap .sectionTitle {
    font-size: 4.2rem;
  }
}
.fooFormSection .row .leftBoxWrap .sectionSubPara {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 4rem;
}
@media (max-width: 767px) {
  .fooFormSection .row .leftBoxWrap .sectionSubPara {
    font-size: 2.6rem;
  }
}
.fooFormSection .row .leftBoxWrap .divider {
  position: relative;
}
.fooFormSection .row .leftBoxWrap .divider::before {
  content: "";
  width: 20%;
  height: 0.1rem;
  background-color: #777777;
  position: absolute;
  top: 0;
  left: 0;
}
.fooFormSection .row .leftBoxWrap .authMsg {
  font-size: 2.2rem;
  color: #fff;
  margin-bottom: 4rem;
  padding-top: 4rem;
  font-style: italic;
}
@media (max-width: 767px) {
  .fooFormSection .row .leftBoxWrap .authNameWrap {
    margin-bottom: 4rem;
  }
}
.fooFormSection .row .leftBoxWrap .authNameWrap .authName {
  font-size: 2rem;
  color: #fff;
  font-weight: 400;
  margin-bottom: 1rem;
}
.fooFormSection .row .leftBoxWrap .authNameWrap .authDesignation {
  font-size: 1.4rem;
  color: #cccccc;
  font-weight: 500;
  margin-bottom: 0;
}
.fooFormSection .formWrap {
  background-color: #fff;
  border-radius: 1.2rem;
  padding: 1rem 3rem;
  border: 0.1rem solid #ccc;
}
.fooFormSection .formWrap .formHeaderSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fooFormSection .formWrap .formHeaderSection .formImgWrap {
  border-radius: 50%;
  max-width: 80px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.fooFormSection .formWrap .formHeaderSection .formData {
  margin-top: 1.8rem;
}
.fooFormSection .formWrap .formHeaderSection .formData h5 {
  font-size: 2.6rem;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
  .fooFormSection .formWrap .formHeaderSection .formData h5 {
    font-size: 2rem;
  }
}
.fooFormSection .formWrap .formHeaderSection .formData p {
  color: #777777;
  font-size: 1.9rem;
  margin-bottom: 0.6rem;
}
@media (max-width: 767px) {
  .fooFormSection .formWrap .formHeaderSection .formData p {
    font-size: 1.6rem;
    text-align: center;
  }
}
.fooFormSection .formWrap .formHeaderSection .formData .btn {
  padding: 1.3rem 2.9rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.8rem;
  color: #f86011;
  font-size: 1.8rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .fooFormSection .formWrap .formHeaderSection .formData .btn {
    font-size: 1.6rem;
  }
}
.fooFormSection .formWrap .formHeaderSection .formData .btn .telPhoneIcon .fa-phone {
  font-size: 2rem;
}
@media (max-width: 767px) {
  .fooFormSection .formWrap .formHeaderSection .formData .btn .telPhoneIcon .fa-phone {
    font-size: 1.6rem;
  }
}
.fooFormSection .formWrap .formHeaderSection .formData .btn:focus {
  outline: none;
  box-shadow: none;
}
.fooFormSection .formWrap .formInputWrap {
  padding-top: 3rem;
}
.fooFormSection .formWrap .formInputWrap label {
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 1rem;
}
.fooFormSection .formWrap .formInputWrap .inputBox {
  width: 100%;
  height: 5.5rem;
  border: 0.1rem solid #d2d2d2;
  border-radius: 1rem;
  padding: 0 1rem;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.fooFormSection .formWrap .formInputWrap .formBtn {
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 767px) {
  .fooFormSection .formWrap .formInputWrap .formBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.fooFormSection .formWrap .formInputWrap .formBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.fooFormSection .formWrap .formInputWrap .formText {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  color: #777777;
  font-size: 1.3rem;
  line-height: 24px;
  font-weight: 500;
}

.faqSection {
  background: #f4f4f6;
  padding: 20rem 0 12rem;
  margin-top: -8rem;
}
@media (max-width: 767px) {
  .faqSection {
    padding: 14rem 0 4rem;
  }
}
.faqSection .sectionTitle h4 {
  font-size: 3.2rem;
  color: #000000;
  font-weight: 500;
  margin-bottom: 6rem;
  text-align: center;
}
@media (max-width: 767px) {
  .faqSection .sectionTitle h4 {
    margin-bottom: 2rem;
  }
}
.faqSection .accordionWrap .accordion {
  width: 100%;
}
.faqSection .accordionWrap .accordion .accordion-item {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  height: 7rem;
}
.faqSection .accordionWrap .accordion .accordion-item:hover {
  background-color: #fff;
}
.faqSection .accordionWrap .accordion .accordion-item:hover .accordion-title {
  color: #f86011;
}
.faqSection .accordionWrap .accordion .accordion-item.open {
  height: 24rem;
}
.faqSection .accordionWrap .accordion .accordion-item.open:hover {
  background-color: #fff;
}
.faqSection .accordionWrap .accordion .accordion-item .accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem;
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
.faqSection .accordionWrap .accordion .accordion-item .accordion-title .icon {
  font-size: 1.8rem;
  color: #f86011;
}
.faqSection .accordionWrap .accordion .accordion-item .accordion-content {
  padding: 1.5rem 2.5rem;
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
}
@media (max-width: 767px) {
  .faqSection .accordionWrap .accordion {
    width: auto;
  }
}
.faqSection .accordionWrap .accordion .accordion-item {
  height: 100%;
}
.faqSection .accordionWrap .accordion .accordion-item .accordion-title {
  font-size: 2rem;
  padding-right: 1.5rem;
}
.faqSection .accordionWrap .accordion .accordion-item.open {
  height: 100%;
}

.contactUsSection {
  padding: 18rem 0 0;
}
@media (max-width: 767px) {
  .contactUsSection {
    padding: 10rem 1.5rem 0;
  }
}
@media (max-width: 767px) {
  .contactUsSection .pad_lr {
    padding: 0;
  }
}
.contactUsSection .sectionTitle h1 {
  font-size: 7.2rem;
  color: #000000;
  font-weight: 500;
  text-align: center;
  margin-bottom: 8rem;
}
@media (max-width: 767px) {
  .contactUsSection .sectionTitle h1 {
    font-size: 4.2rem;
    margin-bottom: 4rem;
  }
}
.contactUsSection .contleftBox .imgWrap {
  margin-bottom: 2.5rem;
}
.contactUsSection .contleftBox .expertData {
  border-bottom: 0.1rem dotted #ccc;
  padding-bottom: 3rem;
}
.contactUsSection .contleftBox .expertData h4 {
  font-size: 2.6rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 1rem;
}
.contactUsSection .contleftBox .expertData p {
  font-size: 1.9rem;
  color: #777777;
}
.contactUsSection .contleftBox .expertData .ulLsit {
  padding-left: 0;
  margin-top: 3rem;
  margin-bottom: 0;
}
.contactUsSection .contleftBox .expertData .ulLsit li {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 1.5rem;
}
.contactUsSection .contleftBox .expertData .ulLsit li:last-child {
  margin-bottom: 0;
}
.contactUsSection .contleftBox .expertData .ulLsit li span svg {
  height: 1.8rem;
}
.contactUsSection .contleftBox .expertData .ulLsit li span svg path {
  fill: #f86011;
}
.contactUsSection .contleftBox .expertQoute {
  padding-top: 3rem;
}
@media (max-width: 767px) {
  .contactUsSection .contleftBox .expertQoute {
    margin-bottom: 3rem;
  }
}
.contactUsSection .contleftBox .expertQoute p {
  font-size: 2.2rem;
  color: #000000;
  margin-bottom: 3rem;
}
.contactUsSection .contleftBox .expertQoute h5 {
  font-size: 2rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 1rem;
}
.contactUsSection .contleftBox .expertQoute span {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 400;
}
.contactUsSection .formWrap {
  background-color: #fff;
  border-radius: 1.2rem;
  padding: 1rem 3rem;
  border: 0.1rem solid #ccc;
}
.contactUsSection .formWrap .formHeaderSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contactUsSection .formWrap .formHeaderSection .formImgWrap {
  border-radius: 50%;
  max-width: 80px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
}
.contactUsSection .formWrap .formHeaderSection .formData {
  margin-top: 1.8rem;
}
.contactUsSection .formWrap .formHeaderSection .formData h5 {
  font-size: 2.6rem;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
  .contactUsSection .formWrap .formHeaderSection .formData h5 {
    font-size: 2rem;
  }
}
.contactUsSection .formWrap .formHeaderSection .formData p {
  color: #777777;
  font-size: 1.9rem;
  margin-bottom: 0.6rem;
}
@media (max-width: 767px) {
  .contactUsSection .formWrap .formHeaderSection .formData p {
    font-size: 1.6rem;
    text-align: center;
  }
}
.contactUsSection .formWrap .formHeaderSection .formData .btn {
  padding: 1.3rem 2.9rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.8rem;
  color: #f86011;
  font-size: 1.8rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .contactUsSection .formWrap .formHeaderSection .formData .btn {
    font-size: 1.6rem;
  }
}
.contactUsSection .formWrap .formHeaderSection .formData .btn .telPhoneIcon .fa-phone {
  font-size: 2rem;
}
@media (max-width: 767px) {
  .contactUsSection .formWrap .formHeaderSection .formData .btn .telPhoneIcon .fa-phone {
    font-size: 1.6rem;
  }
}
.contactUsSection .formWrap .formHeaderSection .formData .btn:focus {
  outline: none;
  box-shadow: none;
}
.contactUsSection .formWrap .formInputWrap {
  padding-top: 3rem;
}
.contactUsSection .formWrap .formInputWrap label {
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
  margin-bottom: 1rem;
}
.contactUsSection .formWrap .formInputWrap .inputBox {
  width: 100%;
  height: 5.5rem;
  border: 0.1rem solid #d2d2d2;
  border-radius: 1rem;
  padding: 0 1rem;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.contactUsSection .formWrap .formInputWrap .formBtn {
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 767px) {
  .contactUsSection .formWrap .formInputWrap .formBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.contactUsSection .formWrap .formInputWrap .formBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.contactUsSection .formWrap .formInputWrap .formText {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
  color: #777777;
  font-size: 1.3rem;
  line-height: 24px;
  font-weight: 500;
}
.contactUsSection .formWrap {
  padding: 1rem 3rem 4rem;
}
.contactUsSection .formWrap .formInputWrap .inputBox {
  margin-bottom: 2.5rem;
}
.contactUsSection .formWrap .formInputWrap .inputBox:hover {
  border: 0.1rem solid red;
}
.contactUsSection .formWrap .formInputWrap .inputBox:focus {
  outline: none;
  box-shadow: none;
}
.contactUsSection .formWrap .formInputWrap .downArrow {
  position: absolute;
  right: 2rem;
  top: 5.5rem;
  color: #777777;
}
.contactUsSection .formWrap .formInputWrap label {
  position: relative;
  width: 100%;
}
.contactUsSection .formWrap .formInputWrap label span .tooltipBox {
  opacity: 0;
  position: absolute;
  top: -60px;
  right: 75px;
  background-color: #000000;
  padding: 1rem 1rem;
  border-radius: 0.6rem;
  pointer-events: none;
}
.contactUsSection .formWrap .formInputWrap label span .tooltipBox span {
  color: #fff;
  font-size: 1.8rem;
}
.contactUsSection .formWrap .formInputWrap label span .tooltipBox::after {
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  background-color: #000000;
  position: absolute;
  bottom: -12px;
  left: 20px;
  transform: rotate(45deg);
}
.contactUsSection .formWrap .formInputWrap label span i {
  margin-left: 1rem;
  cursor: pointer;
}
.contactUsSection .formWrap .formInputWrap label span:hover .tooltipBox {
  opacity: 1;
}
.contactUsSection .formWrap .formInputWrap .form-check {
  margin-bottom: 3rem;
  padding-left: 0;
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -1rem;
  margin-right: 0.8rem;
  border-color: #777777;
  cursor: pointer;
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-input:focus {
  outline: none;
  box-shadow: none;
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-input.custom-checkbox {
  display: none;
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-input.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #777777;
  background-color: #fff;
  margin-right: 10px;
  vertical-align: middle;
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-input.custom-checkbox:checked + label::before {
  border: 0.1rem solid #f86011;
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-input.custom-checkbox:checked + label::after {
  content: "";
  position: absolute;
  width: 0.6rem;
  height: 0.6rem;
  left: 0.4rem;
  background: red;
  border-radius: 0.2rem;
  top: 55%;
  transform: translateY(-50%);
}
.contactUsSection .formWrap .formInputWrap .form-check .form-check-label {
  cursor: pointer;
  width: auto;
}
.contactUsSection .formWrap .formInputWrap textarea {
  height: 15rem !important;
  padding: 2rem 1rem !important;
}
.contactUsSection .formWrap .formInputWrap .select {
  width: 100%;
  height: 5.5rem;
  border: 0.1rem solid #d2d2d2;
  border-radius: 1rem;
  padding: 0 1rem;
  font-size: 1.8rem;
  color: #777777;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  margin-bottom: 3rem;
}
.contactUsSection .formWrap .formInputWrap .select:hover {
  border: 0.1rem solid red;
  cursor: pointer;
}
.contactUsSection .formWrap .formInputWrap .select:focus {
  outline: none;
  box-shadow: none;
}

.whyusSection .bannerSection {
  padding: 18rem 0 12rem;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection {
    padding: 12rem 0 6rem;
  }
}
@media (max-width: 834px) {
  .whyusSection .bannerSection .row .col-md-5 {
    width: 100%;
  }
}
@media (max-width: 834px) {
  .whyusSection .bannerSection .row .col-md-7 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerLeftWrap {
    padding: 0 1.5rem;
  }
}
.whyusSection .bannerSection .innerLeftWrap .ulLsit {
  padding-left: 0;
  margin-top: 3rem;
  margin-bottom: 0;
}
.whyusSection .bannerSection .innerLeftWrap .ulLsit li {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  font-size: 1.8rem;
  color: #000000;
  margin-bottom: 1.5rem;
}
.whyusSection .bannerSection .innerLeftWrap .ulLsit li:last-child {
  margin-bottom: 0;
}
.whyusSection .bannerSection .innerLeftWrap .ulLsit li span svg {
  height: 1.8rem;
}
.whyusSection .bannerSection .innerLeftWrap .ulLsit li span svg path {
  fill: #f86011;
}
.whyusSection .bannerSection .innerLeftWrap h1 {
  font-size: 7.2rem;
  color: #000000;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 3rem;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerLeftWrap h1 {
    font-size: 4.2rem;
  }
}
.whyusSection .bannerSection .innerLeftWrap p {
  font-size: 3rem;
  color: #000000;
  font-weight: 400;
  padding-right: 3rem;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerLeftWrap p {
    font-size: 2.6rem;
    padding-right: 0;
  }
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap {
  margin-top: 4rem;
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  gap: 1.5rem;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn .fa-phone {
  display: none;
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn span {
  color: #fff;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn span {
    font-size: 1.6rem;
  }
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .w-45 {
  width: 45%;
}
@media (max-width: 1024px) {
  .whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .w-45 {
    width: 75%;
  }
}
@media (max-width: 834px) {
  .whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .w-45 {
    width: 45%;
  }
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerLeftWrap .readMoreBtnWrap .w-45 {
    width: 60%;
  }
}
.whyusSection .bannerSection .innerLeftWrap .ulLsit {
  margin-bottom: 3rem;
}
.whyusSection .bannerSection .innerRightWrap {
  position: relative;
  padding-right: 7rem;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerRightWrap {
    padding-right: 0;
  }
}
.whyusSection .bannerSection .innerRightWrap::after {
  content: "";
  position: absolute;
  bottom: -6rem;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f6;
  z-index: -1;
}
.whyusSection .bannerSection .innerRightWrap img {
  position: relative;
}
.whyusSection .bannerSection .innerRightWrap .overlayTextWrap {
  width: 45%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2.5rem;
  box-shadow: 0 5rem 7rem 0 #ccc;
}
@media (max-width: 767px) {
  .whyusSection .bannerSection .innerRightWrap .overlayTextWrap {
    width: 100%;
  }
}
.whyusSection .bannerSection .innerRightWrap .overlayTextWrap h5 {
  font-size: 2rem;
  color: #000000;
  font-weight: 700;
  margin-bottom: 1.2rem;
}
.whyusSection .bannerSection .innerRightWrap .overlayTextWrap p {
  font-size: 2rem;
  color: #000000;
  font-weight: 400;
  margin-bottom: 1.2rem;
}
.whyusSection .bannerSection .innerRightWrap .overlayTextWrap h6 {
  font-size: 2rem;
  color: #000000;
  font-weight: 500;
  margin-bottom: 1rem;
}
.whyusSection .bannerSection .innerRightWrap .overlayTextWrap span {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 400;
}
.whyusSection .strategySection {
  padding: 0 0 12rem;
}
@media (max-width: 767px) {
  .whyusSection .strategySection {
    padding: 0 1.5rem 6rem;
  }
}
.whyusSection .strategySection .row {
  row-gap: 8rem;
}
@media (max-width: 767px) {
  .whyusSection .strategySection .row {
    row-gap: 3rem;
  }
}
.whyusSection .strategySection .sectionTitle {
  width: 60%;
  margin: 0 auto 8rem;
}
@media (max-width: 1024px) {
  .whyusSection .strategySection .sectionTitle {
    width: 85%;
  }
}
@media (max-width: 767px) {
  .whyusSection .strategySection .sectionTitle {
    width: 100%;
    margin: 0 auto 3rem;
  }
}
.whyusSection .strategySection .sectionTitle h4 {
  font-size: 5.4rem;
  color: #000000;
  text-align: center;
}
@media (max-width: 767px) {
  .whyusSection .strategySection .sectionTitle h4 {
    font-size: 3.4rem;
  }
}
.whyusSection .strategySection .readMoreBtnWrap {
  margin-top: 8rem;
}
@media (max-width: 767px) {
  .whyusSection .strategySection .readMoreBtnWrap {
    margin-top: 3rem;
  }
}
.whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  gap: 1.5rem;
}
@media (max-width: 767px) {
  .whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
.whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn .fa-phone {
  display: none;
}
.whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn span {
  color: #fff;
  font-size: 1.8rem;
}
@media (max-width: 767px) {
  .whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn span {
    font-size: 1.6rem;
  }
}
.whyusSection .strategySection .readMoreBtnWrap .btn.greenBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
@media (max-width: 1024px) {
  .whyusSection .strategySection .readMoreBtnWrap .w-25 {
    width: 40% !important;
  }
}
@media (max-width: 767px) {
  .whyusSection .strategySection .readMoreBtnWrap .w-25 {
    width: 65% !important;
  }
}
.whyusSection .strategySection .strgWrap {
  display: flex;
  gap: 2rem;
}
@media (max-width: 767px) {
  .whyusSection .strategySection .strgWrap {
    flex-direction: column;
  }
}
.whyusSection .strategySection .strgWrap .iconBox {
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whyusSection .strategySection .strgWrap .iconBox img {
  width: 75%;
}
.whyusSection .strategySection .strgWrap .contentBox h5 {
  font-size: 2.4rem;
  color: #000000;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.whyusSection .strategySection .strgWrap .contentBox h5:hover {
  color: #f86011;
}
.whyusSection .strategySection .strgWrap .contentBox p {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 0;
}
.whyusSection .webSecuritySection .sectionTitle p {
  width: 65%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .whyusSection .webSecuritySection .sectionTitle p {
    width: 100%;
  }
}
.whyusSection .expertTalkSection {
  padding: 12rem 0 12rem;
}
@media (max-width: 834px) {
  .whyusSection .expertTalkSection .col-md-6 {
    width: 100%;
  }
  .whyusSection .expertTalkSection .offset-1 {
    margin-top: 3rem;
    margin-left: 0;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .whyusSection .expertTalkSection {
    padding: 3rem 1.5rem 6rem;
  }
  .whyusSection .expertTalkSection .offset-1 {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .whyusSection .expertTalkSection .talkRightBox {
    margin-top: 3rem;
  }
}
.whyusSection .expertTalkSection .talkRightBox h5 {
  font-size: 3.2rem;
  color: #000000;
  font-weight: 500;
  line-height: 1.26;
  margin-bottom: 4rem;
}
@media (max-width: 834px) {
  .whyusSection .expertTalkSection .talkRightBox h5 {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .whyusSection .expertTalkSection .talkRightBox h5 {
    font-size: 2.6rem;
  }
}
.whyusSection .expertTalkSection .talkRightBox h6 {
  font-size: 2rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 1rem;
}
@media (max-width: 834px) {
  .whyusSection .expertTalkSection .talkRightBox h6 {
    text-align: center;
  }
}
.whyusSection .expertTalkSection .talkRightBox span {
  font-size: 1.4rem;
  font-weight: 500;
}
@media (max-width: 834px) {
  .whyusSection .expertTalkSection .talkRightBox span {
    text-align: center;
    display: block;
  }
}
.whyusSection .darkBG .awardSection {
  padding-top: 0;
}
.whyusSection .faqSection {
  padding: 6rem 0 12rem;
  margin-top: 0;
  background-color: #fff;
}
@media (max-width: 767px) {
  .whyusSection .faqSection {
    padding: 3rem 0 4rem;
  }
  .whyusSection .faqSection .sectionTitle h4 {
    margin-bottom: 2rem;
    font-size: 2.6rem;
  }
  .whyusSection .faqSection .accordion-item {
    height: 100% !important;
  }
  .whyusSection .faqSection .accordion-item .accordion-title {
    font-size: 1.8rem;
  }
}
.whyusSection .chartSection {
  padding: 12rem 0 8rem;
}
@media (max-width: 767px) {
  .whyusSection .chartSection {
    padding: 6rem 0 0rem;
  }
}
.whyusSection .chartSection .chartRow {
  justify-content: space-between;
}
.whyusSection .chartSection .chartRow .col-md-5 {
  width: 45%;
  background-color: #f5f5f7;
  padding: 4rem;
  border-radius: 1.2rem;
}
.whyusSection .chartSection .chartRow .col-md-5 .chartCardWrap .chartImgWrap {
  background-color: #fff;
}
.whyusSection #organisationSection {
  padding: 0rem 0 6rem;
}
@media (max-width: 1024px) {
  .whyusSection #organisationSection .readMoreBtnWrap {
    width: 35%;
  }
}
@media (max-width: 767px) {
  .whyusSection #organisationSection .readMoreBtnWrap {
    width: 75%;
  }
}
.whyusSection .absTestimonialSection {
  padding: 8rem 0 6rem;
}
@media (max-width: 767px) {
  .whyusSection .absTestimonialSection {
    padding: 0rem 0 6rem;
  }
}
.whyusSection .absTestimonialSection .abstestiWrap {
  text-align: center;
  padding: 0 5rem;
}
@media (max-width: 834px) {
  .whyusSection .absTestimonialSection .abstestiWrap {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .whyusSection .absTestimonialSection .abstestiWrap {
    padding: 0rem 1.5rem 0rem;
  }
}
.whyusSection .absTestimonialSection .abstestiWrap img {
  max-width: 110px;
  margin-bottom: 2rem;
}
.whyusSection .absTestimonialSection .abstestiWrap h5 {
  font-size: 3.2rem;
  color: #000000;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .whyusSection .absTestimonialSection .abstestiWrap h5 {
    font-size: 2.6rem;
  }
}
.whyusSection .absTestimonialSection .abstestiWrap .profileImgWrap {
  border-radius: 50%;
  width: 8rem;
  overflow: hidden;
  margin: 3rem auto 1rem;
  height: 8rem;
}
.whyusSection .absTestimonialSection .abstestiWrap .profileImgWrap img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  margin-bottom: 0;
}
.whyusSection .absTestimonialSection .abstestiWrap h6 {
  font-size: 2rem;
  color: #000000;
  font-size: 500;
  margin-bottom: 1rem;
}
.whyusSection .absTestimonialSection .abstestiWrap span {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 400;
}
.whyusSection .fooFormSection {
  width: 60%;
  margin: 0 auto;
  border-radius: 1.2rem;
}
@media (max-width: 1024px) {
  .whyusSection .fooFormSection {
    width: 85%;
  }
}
@media (max-width: 834px) {
  .whyusSection .fooFormSection {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .whyusSection .fooFormSection {
    width: 100%;
  }
}
.whyusSection .fooFormSection .row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.whyusSection .fooFormSection .row .col-md-6 {
  width: 75%;
}
@media (max-width: 767px) {
  .whyusSection .fooFormSection .row .col-md-6 {
    width: 100%;
  }
}
.whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap {
  padding-right: 0;
}
.whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap .sectionTitle {
  margin-bottom: 5rem;
  text-align: center;
  padding: 0 6rem;
}
@media (max-width: 767px) {
  .whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap .sectionTitle {
    padding: 0;
  }
}
.whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap .sectionSubPara {
  display: none;
}
.whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap .divider {
  display: none;
}
.whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap .authMsg {
  display: none;
}
.whyusSection .fooFormSection .row .col-md-6 .leftBoxWrap .authNameWrap {
  display: none;
}
.whyusSection .fooFormSection .row .col-md-5 {
  width: 66.66666667%;
}

.footer {
  padding: 5rem 0 0;
}
.footer .textRow {
  margin-bottom: 5rem;
}
.footer .textRow h4 {
  text-align: center;
  font-size: 3.2rem;
  color: #000000;
}
@media (max-width: 767px) {
  .footer .textRow h4 {
    font-size: 2.6rem;
    line-height: 1.4;
  }
}
.footer .copyRightRow {
  background-color: #000000;
}
@media (max-width: 767px) {
  .footer .copyRightRow {
    padding: 0 0 1rem;
  }
}
.footer .copyRightRow .copyRightBox p {
  font-size: 1.6rem;
  color: #fff;
  padding: 1.5rem 0;
  margin-bottom: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .footer .copyRightRow .copyRightBox p {
    font-size: 1.4rem;
  }
}
.footer .copyRightRow .social_row {
  height: 100%;
  justify-content: center;
  display: flex;
}
.footer .copyRightRow .social_row ul {
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0;
}
.footer .copyRightRow .social_row ul li a i {
  font-size: 2rem;
  color: #fff;
}
@media (max-width: 767px) {
  .footer .copyRightRow .social_row ul li a i {
    font-size: 1.4rem;
  }
}

.conetentSection {
  padding: 12rem 0 6rem;
}
@media (max-width: 767px) {
  .conetentSection {
    padding: 6rem 1.5rem 6rem;
  }
}
.conetentSection .abtContentWrap p {
  font-size: 2rem;
  color: #000000;
  line-height: 1.6;
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .conetentSection .abtContentWrap p {
    font-size: 1.8rem;
  }
}
.conetentSection .abtContentWrap p:last-child {
  margin-bottom: 0;
}
.conetentSection .abtPageImgWrap {
  border-radius: 1.2rem;
  overflow: hidden;
  height: 58rem;
  margin-bottom: 4rem;
}
@media (max-width: 834px) {
  .conetentSection .abtPageImgWrap {
    margin-top: 4rem;
  }
}
@media (max-width: 767px) {
  .conetentSection .abtPageImgWrap {
    height: 100%;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
}

.webSecuritySection {
  padding: 0 0 6rem;
}
.webSecuritySection .sectionTitle {
  margin-bottom: 6rem;
}
@media (max-width: 767px) {
  .webSecuritySection .sectionTitle {
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }
}
.webSecuritySection .sectionTitle h3 {
  font-size: 5.4rem;
  color: #000000;
  line-height: 1.2;
  margin-bottom: 1rem;
  text-align: center;
}
@media (max-width: 767px) {
  .webSecuritySection .sectionTitle h3 {
    font-size: 3.4rem;
  }
}
.webSecuritySection .sectionTitle p {
  font-size: 3rem;
  color: #000000;
  text-align: center;
}
@media (max-width: 767px) {
  .webSecuritySection .sectionTitle p {
    font-size: 2.6rem;
  }
}
.webSecuritySection .webSecureCard {
  padding: 0 2rem;
}
@media (max-width: 1024px) {
  .webSecuritySection .webSecureCard {
    width: 50%;
  }
}
@media (max-width: 834px) {
  .webSecuritySection .webSecureCard {
    margin-bottom: 5rem;
    width: 50%;
  }
}
@media (max-width: 767px) {
  .webSecuritySection .webSecureCard {
    margin-bottom: 3rem;
    width: 100%;
  }
}
.webSecuritySection .webSecureCard .webSecureCardWrap {
  border-width: 0 1px 1px 1px;
  border-color: #ebebeb;
  border-style: solid;
  padding: 3rem 2.5rem;
  border-radius: 1.2rem;
  text-align: center;
  box-shadow: 0px 10px 32px -20px #777777;
  height: auto;
}
@media (max-width: 834px) {
  .webSecuritySection .webSecureCard .webSecureCardWrap {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .webSecuritySection .webSecureCard .webSecureCardWrap {
    height: auto;
  }
}
.webSecuritySection .webSecureCard .webSecureCardWrap h5 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #000000;
}
.webSecuritySection .webSecureCard .webSecureCardWrap p {
  font-size: 2rem;
  color: #000000;
  font-weight: 300;
}
.webSecuritySection .webSecureCard .webSecureCardWrap .cardImgWrap {
  height: 12rem;
  width: 12rem;
  margin: 4rem auto 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.webSecuritySection .webSecureCard .webSecureCardWrap .getQuote.w-75 {
  width: 90% !important;
}

.darkBG {
  background: #fff;
  padding: 6rem 0;
}
@media (max-width: 767px) {
  .darkBG {
    padding: 3rem 0;
  }
}

.socialMediaSection {
  padding: 12rem 0 0;
}
@media (max-width: 767px) {
  .socialMediaSection {
    padding: 6rem 0 0;
  }
}
.socialMediaSection .sectionTitle {
  margin-bottom: 10rem;
}
@media (max-width: 767px) {
  .socialMediaSection .sectionTitle {
    margin-bottom: 4rem;
  }
}
.socialMediaSection .sectionTitle h4 {
  font-size: 5.4rem;
  font-weight: 500;
  color: #000000;
  margin-bottom: 5rem;
  text-align: center;
  width: 35%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .socialMediaSection .sectionTitle h4 {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .socialMediaSection .sectionTitle h4 {
    width: 100%;
    font-size: 3.4rem;
  }
}
.socialMediaSection .sectionTitle p {
  font-size: 2.4rem;
  color: #000000;
  text-align: center;
  width: 50%;
  margin: 3rem auto auto;
}
@media (max-width: 767px) {
  .socialMediaSection .sectionTitle p {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .socialMediaSection .socialMediaLeftBox {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .socialMediaSection .socialMediaLeftBox img {
    max-width: 75%;
  }
}
.socialMediaSection .accordionWrap .accordion {
  width: 100%;
}
.socialMediaSection .accordionWrap .accordion .accordion-item {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  height: 7rem;
}
.socialMediaSection .accordionWrap .accordion .accordion-item:hover {
  background-color: #fff;
}
.socialMediaSection .accordionWrap .accordion .accordion-item:hover .accordion-title {
  color: #f86011;
}
.socialMediaSection .accordionWrap .accordion .accordion-item.open {
  height: 24rem;
}
.socialMediaSection .accordionWrap .accordion .accordion-item.open:hover {
  background-color: #fff;
}
.socialMediaSection .accordionWrap .accordion .accordion-item .accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem;
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
.socialMediaSection .accordionWrap .accordion .accordion-item .accordion-title .icon {
  font-size: 1.8rem;
  color: #f86011;
}
.socialMediaSection .accordionWrap .accordion .accordion-item .accordion-content {
  padding: 1.5rem 2.5rem;
  font-size: 2rem;
  font-weight: 400;
  color: #000000;
}
@media (max-width: 834px) {
  .socialMediaSection .accordion-item {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .socialMediaSection .accordion-item.open {
    height: 100% !important;
  }
}
.socialMediaSection .accordion-title {
  flex-direction: row-reverse;
  justify-content: flex-end !important;
  gap: 3rem;
  font-size: 3rem !important;
}
@media (max-width: 834px) {
  .socialMediaSection .accordion-title {
    font-size: 2rem !important;
  }
}
@media (max-width: 767px) {
  .socialMediaSection .accordion-title {
    font-size: 2.4rem !important;
  }
}
.socialMediaSection .accordion-title .icon {
  font-size: 2rem !important;
}

.innervas #ourClients {
  padding: 8rem 0 12rem;
}
@media (max-width: 767px) {
  .innervas #ourClients {
    padding: 3rem 0 2rem;
  }
}

.chartSection {
  padding: 12rem 0 16rem;
}
@media (max-width: 834px) {
  .chartSection {
    padding: 0rem 0 8rem;
  }
}
@media (max-width: 767px) {
  .chartSection {
    padding: 0rem 0 0rem;
  }
}
.chartSection .chartRow {
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .chartSection .chartRow .col-lg-5 {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .chartSection .chartRow .col-lg-5 {
    width: 100%;
  }
}
.chartSection .chartRow .chartCardWrap {
  padding: 0 2rem;
}
.chartSection .chartRow .chartCardWrap h4 {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #000000;
  text-align: center;
}
.chartSection .chartRow .chartCardWrap p {
  font-size: 2rem;
  color: #000000;
  font-weight: 400;
  text-align: center;
  margin-bottom: 6rem;
}
.chartSection .chartRow .chartCardWrap .chartImgWrap {
  border-width: 0 1px 1px 1px;
  border-color: #ebebeb;
  border-style: solid;
  border-radius: 1.2rem;
  box-shadow: 0px 10px 32px -20px #777777;
  padding: 3rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem;
}
@media (max-width: 767px) {
  .chartSection .chartRow .chartCardWrap .chartImgWrap {
    margin-bottom: 5rem;
    height: 15rem;
  }
}
.chartSection .chartRow .chartCardWrap .chartImgWrap img {
  max-width: 350px;
}
@media (max-width: 1024px) {
  .chartSection .chartRow .chartCardWrap .chartImgWrap img {
    max-width: 300px;
  }
}
@media (max-width: 834px) {
  .chartSection .chartRow .chartCardWrap .chartImgWrap img {
    max-width: 210px;
  }
}
@media (max-width: 767px) {
  .chartSection .chartRow .chartCardWrap .chartImgWrap img {
    max-width: 205px;
  }
}

.successCountSection .countRow .countBoxWrap {
  text-align: center;
}
@media (max-width: 767px) {
  .successCountSection .countRow .countBoxWrap {
    margin-bottom: 3rem;
  }
}
.successCountSection .countRow .countBoxWrap .boxImgWrap {
  margin-bottom: 1rem;
}
.successCountSection .countRow .countBoxWrap .boxImgWrap img {
  width: 2rem;
}
.successCountSection .countRow .countBoxWrap h3 {
  font-size: 7.2rem;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
@media (max-width: 834px) {
  .successCountSection .countRow .countBoxWrap h3 {
    font-size: 5.2rem;
  }
}
@media (max-width: 767px) {
  .successCountSection .countRow .countBoxWrap h3 {
    font-size: 3.2rem;
  }
}
.successCountSection .countRow .countBoxWrap p {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .successCountSection .countRow .countBoxWrap p {
    font-size: 2rem;
  }
}

.ourMethodologySection {
  padding: 12rem 0 0;
}
@media (max-width: 767px) {
  .ourMethodologySection {
    padding: 6rem 2rem 0;
  }
}
.ourMethodologySection .methodImgWrap {
  padding: 0 3rem;
}
@media (max-width: 834px) {
  .ourMethodologySection .methodImgWrap {
    margin-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  .ourMethodologySection .methodImgWrap {
    margin-bottom: 3rem;
  }
}
.ourMethodologySection .methodparaWrap h5 {
  font-size: 2rem;
  color: #000000;
  font-weight: 600;
  margin-bottom: 2.5rem;
}
.ourMethodologySection .methodparaWrap p {
  font-size: 2rem;
  color: #000000;
  font-weight: 400;
  margin-bottom: 2rem;
  line-height: 1.6;
}
.ourMethodologySection .methodparaWrap ul li {
  list-style: disc;
  font-size: 2rem;
  color: #000000;
  font-weight: 400;
  line-height: 1.6;
}

.innerBannerSection {
  padding: 22rem 0 0;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .innerBannerSection {
    padding: 14rem 0 0;
  }
}
.innerBannerSection::before {
  content: "";
  background: #e9fcf2;
  width: 200rem;
  height: 200rem;
  border-radius: 100%;
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .innerBannerSection::before {
    height: 107%;
  }
}
.innerBannerSection .bannerTextWrap {
  text-align: center;
}
.innerBannerSection .bannerTextWrap span {
  font-size: 2rem;
  color: #000000;
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 500;
}
.innerBannerSection .bannerTextWrap h1 {
  font-size: 7.2rem;
  color: #000000;
  line-height: 1;
  font-weight: 500;
  padding: 0 6rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 834px) {
  .innerBannerSection .bannerTextWrap h1 {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .innerBannerSection .bannerTextWrap h1 {
    font-size: 3.2rem;
    padding: 0 3rem;
    line-height: 1.2;
  }
}
.innerBannerSection .bannerTextWrap p {
  font-size: 3rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  .innerBannerSection .bannerTextWrap p {
    font-size: 2.6rem;
  }
}
.innerBannerSection .getConsultBtnWrap {
  margin: 3rem 0 8rem;
}
.innerBannerSection .getConsultBtnWrap .btn.consultBtn {
  height: 5.5rem;
  background: linear-gradient(180deg, rgb(0, 165, 81) 0%, rgb(0, 145, 77) 60%);
  border-radius: 1.2rem;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: 0 auto;
  background: linear-gradient(180deg, #f86011 0%, #f86011 100%);
}
@media (max-width: 767px) {
  .innerBannerSection .getConsultBtnWrap .btn.consultBtn {
    height: 4.5rem;
    font-size: 1.6rem;
  }
}
.innerBannerSection .getConsultBtnWrap .btn.consultBtn:hover {
  background: linear-gradient(180deg, rgb(0, 142, 76) 0%, rgb(0, 142, 76) 100%);
}
@media (max-width: 834px) {
  .innerBannerSection .getConsultBtnWrap .btn.consultBtn {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .innerBannerSection .getConsultBtnWrap .btn.consultBtn {
    width: 60%;
  }
}
.innerBannerSection .getConsultBtnWrap .btn.consultBtn i {
  display: none;
}
.innerBannerSection .getConsultBtnWrap .btn.consultBtn:hover {
  background: linear-gradient(180deg, #e55d13 0%, #e55d13 100%);
}

.vaptimgSection {
  padding-top: 8rem;
}
.vaptimgSection .sectionTitle h4 {
  text-align: center;
  font-size: 2.6rem;
  font-weight: 600;
}/*# sourceMappingURL=main.css.map */