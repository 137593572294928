// color
$white-color: #fff;
$orange-color: #f86011;
$green-color: #00a551;
$black-color: #000000;
$light-black-color: #777777;
$ctaGradient: linear-gradient(180deg, rgba(0,165,81,1) 0%, rgba(0,145,77,1) 60%);
$ctaGradient-dark: linear-gradient(180deg, rgba(0,142,76,1) 0%, rgba(0,142,76,1) 100%);
$ctaGradient-orange: linear-gradient(180deg, #f86011 0%, #f86011 100%);
$ctaGradient-orange-dark: linear-gradient(180deg, #e55d13 0%, #e55d13 100%);
// Font Size
$bannerTitle: 7.2rem;
$bannerPara: 3rem;
$ctaText: 1.8rem;
$sectionTitle:5.4rem;
$sectionPara:2.4rem;

//Custom Width
$custom_width_16: 16.666666666667%;
$custom_width_8: 8%;
$custom_width_20: 20%;
$custom_width_46: 46%;
$custom_width_26: 26%;

// Font weight
$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
$fw-bold: "bold";
