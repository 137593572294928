@import "../css/_variables";
@import "../css/mixin";
@import "../css/media-queries";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&family=Nunito:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.33%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}
ul {
  li {
    list-style: none;
  }
}
a {
  text-decoration: none;
}
body {
  overflow-x: hidden;
  position: relative;
  font-family: "DM Sans", Arial, Helvetica, sans-serif;
}
// NavigationBar CSS Start
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white-color;
  display: block;
  transition: top 0.3s;
  padding: 1.5rem 0;
  z-index: 1000;
  @include x-767-tablet {
    width: 100vw;
  }
  #navigationBar {
    .navbar-brand {
      img {
        max-width: 70%;
        @include md-tablet {
          max-width: 90%;
        }
        @include x-767-tablet {
          max-width: 50%;
        }
      }
    }
    #basic-navbar-nav {
      #deskMenu {
        @include mid-991-tab {
          display: none;
        }
      }
      #mobMenu {
        @include min-tab {
          display: none;
        }
      }
      .navbar-nav {
        @include md-tablet {
          &.pe-5 {
            padding-right: 0 !important;
          }
        }
        .drop_Down {
          position: relative;

          .drop_down_menus {
            border-radius: 1.2rem;
            position: absolute;
            width: 115rem;
            z-index: 10;
            left: -555%;
            transform: translateY(0);
            background: $white-color;
            padding: 3rem;
            border: 0.1rem solid #7777771f;
            opacity: 0;
            pointer-events: none;
            transition: transform 0.3s;
            
            .row {
              @include md-tablet {
                row-gap: 4rem;
                .col-md-4 {
                  width: 100%;
                }
              }
            }
            @include d-desktop{
              width: 95rem;
              left: -415%;
            }
            @include md-tablet {
              width: 100%;
              left: 0;
            }
            &:hover {
              .drop_Down {
                display: none;
              }
            }
            .drop_menu_innerBox {
              ul {
                padding-left: 0;
                margin-bottom: 0;
                li {
                  margin-bottom: 3rem;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  .drop_menu_link {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    &:hover {
                      .menu_name {
                        color: $orange-color;
                      }
                    }
                    .menueWrap {
                      display: flex;
                      gap: 1.5rem;
                      align-items: center;
                      .menuTextWrap {
                        display: flex;
                        flex-direction: column;
                      }
                    }
                    .menu_name {
                      font-size: 1.8rem;
                      font-weight: 600;
                      color: $black-color;
                    }
                    .menu_desc {
                      font-size: 1.4rem;
                      font-weight: 400;
                      color: $black-color;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            .drop_down_menus {
              opacity: 1;
              transform: translateY(0);
              display: block;
              pointer-events: all;
            }
          }
        }
        .drop_Down {
          &:hover {
            .nav-link {
              color: $orange-color;
            }
            // border-bottom: 0.2rem solid $orange-color;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 0.1rem;
            background-color: $orange-color;
            transition: width 0.3s;
          }
          &:hover {
            &.active {
              &::after {
                display: none;
              }
            }
            &::after {
              width: 100%;
            }
          }
          .nav-link {
            font-size: 1.8rem;
            font-weight: $fw-600;
            color: $black-color;
            position: relative;
            display: flex;

            &.active {
              color: $orange-color;
              border-bottom: 0.2rem solid $orange-color;
            }
            &.dropdown-toggle {
              position: relative;
              &::after {
                display: none;
              }
            }
          }
        }
      }
      &.navbar-collapse {
        @include mid-991-tab {
          background: $white-color;
          box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
          display: block;
          height: 100%;
          left: -100%;
          overflow-y: auto;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 30px;
          position: fixed;
          top: 0;
          transition: all 0.2s cubic-bezier(0, 0, 0.2, 1) 0.1s !important;
          width: 50%;
          z-index: 999999;
        }
        @include mid-991-tab {
          width: 80%;
        }
        &.show {
          left: 0;
          .navbar-nav {
            .nav-item {
              border-bottom: 1px solid #fff;
              padding-bottom: 1.5rem;
              &:last-child {
                border-bottom: 0;
              }
              .nav-link {
                justify-content: space-between;
                font-size: 2rem;
                color: unset;
                position: relative;
                &:hover {
                  color: #2276b9;
                  @include mid-991-tab {
                    color: #fff;
                  }
                }
                @include mid-991-tab {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
.navNumber {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: $fw-600;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  i {
    color: $orange-color;
    font-size: 2.2rem;
  }
}
.getQuote {
  .btn {
    &.orgBtn {
      @include centerelm;
      @include greenButton;
      background: #feefe7;
      gap: 1.5rem;
      padding: 0 4rem;

      i {
        display: none;
      }
      span {
        color: $orange-color;
        font-size: $ctaText;
        @include x-767-tablet {
          font-size: 1.6rem;
        }
      }
      &:hover {
        background: $orange-color;
        span {
          color: $white-color;
        }
      }
    }
  }
}
// Banner Section CSS Start
#banner_section {
  background-image: url(../images/banner/cyber-security-services.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 70rem;
  margin-top: 8rem;
  padding-top: 10rem;
  @include x-767-tablet {
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .formRow {
    justify-content: space-between;
  }
  .sectionTitle {
    color: $white-color;
    font-size: $bannerTitle;
    font-weight: $fw-500;
    margin-bottom: 2rem;
    @include x-767-tablet {
      font-size: 4.2rem;
      text-align: center;
    }
    .svgWrap {
      position: relative;
      display: inline-block;
      .svgBox {
        position: relative;
        display: inline-block;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 0.75em);
        height: calc(100% + 0.75em);
        transform: translate(-50%, -50%);
        overflow: visible;
        margin-top: -0.8rem;
        path {
          stroke: $green-color;
          fill: none;
          stroke-width: 8;
          stroke-dasharray: 1500;
          stroke-linecap: round;
          stroke-linejoin: round;
          animation: svgAnimation 10s linear infinite;
        }
      }
    }
  }
  @keyframes svgAnimation {
    0% {
      stroke-dasharray: 0 1500;
      opacity: 1;
    }
    15% {
      stroke-dasharray: 1500 1500;
    }
    85% {
      opacity: 1;
    }
    90% {
      stroke-dasharray: 1500 1500;
      opacity: 0;
    }
    100% {
      stroke-dasharray: 0 1500;
      opacity: 0;
    }
  }
  .sectionPara {
    font-size: $bannerPara;
    font-weight: $fw-400;
    color: $white-color;
    margin-bottom: 3rem;
    line-height: 1.5;
    @include x-767-tablet {
      text-align: center;
      font-size: 2.4rem;
      padding: 0 1.5rem;
    }
  }
  .ctaWrap {
    @include x-767-tablet {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
    }
    .btn {
      background-color: $white-color;
      border: 0.2rem solid $black-color;
      padding: 1.3rem 2.9rem;
      border-radius: 1.2rem;
      @include centerelm;
      width: fit-content;
      .btnText {
        font-size: $ctaText;
        color: $black-color;
        font-weight: $fw-500;
        padding-right: 1rem;
      }
      .material-symbols-outlined {
        color: $black-color;
        font-size: 2.3rem;
      }
      &:hover {
        background-color: transparent;
        border: 0.2rem solid $white-color;
        .btnText {
          color: $white-color;
        }
        .material-symbols-outlined {
          color: $white-color;
        }
      }
    }
  }

  @include form;
}
.pad_lr {
  padding: 0 2.5rem;
  @include md-tablet{
    padding: 0;
  }
}
// Consultation Section CSS Start
#consultationSection {
  padding: 15rem 0 0;
  @include x-767-tablet {
    padding: 5rem 1.5rem 0;
  }
  .row {
    justify-content: space-evenly;
    margin-bottom: 10rem;
    @include x-767-tablet {
      margin-bottom: 2rem;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    .col-md-4{
      @include d-desktop{
        width: 50%;
      }
      @include x-767-tablet{
        width: 100% !important;
      }
    }
    .right_anime_box {
      h6 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.5rem;
        font-size: 2rem;
        font-weight: $fw-500;
        color: $black-color;
        line-height: 3rem;
        span {
          display: block;
          width: 4.4rem;
          height: 4.4rem;
        }
      }
      h3 {
        font-size: $sectionTitle;
        font-weight: $fw-500;
        margin-top: 2rem;
        margin-bottom: 3rem;
        @include x-767-tablet {
          font-size: 3.4rem;
          margin-bottom: 2rem;
        }
      }
      p {
        font-size: $sectionPara;
        font-weight: $fw-400;
        @include x-767-tablet {
          font-size: 2rem;
        }
      }
      .consultBtn {
        margin-top: 3rem;
        @include centerelm;
        @include greenButton;
        @include d-desktop{
          width: 75%!important;
        }
        @include x-767-tablet {
          width: 75% !important;
        }
      }
    }
  }
}

// Our Clients Section CSS Start
#ourClients {
  padding: 2rem 0 10rem;
  @include x-767-tablet {
    padding: 2rem 0 3rem;
  }
  .clientWrap {
    .sectionTitle {
      font-size: $sectionTitle;
      font-weight: $fw-500;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
      @include x-767-tablet {
        font-size: 3.4rem;
      }
    }
    p {
      font-size: $sectionPara;
      font-weight: $fw-400;
      text-align: center;
      width: 75%;
      margin: 0 auto;
      @include x-767-tablet {
        width: 85%;
      }
    }
    // .clientsLogoWrap {
    //   margin: 4rem 0;
    //   @include centerelm;
    //   justify-content: space-between;
    //   .custom_width_16 {
    //     width: $custom_width_16;
    //     @include centerelm;
    //     .anchorDiv {
    //       max-width: 14.8rem;
    //     }
    //   }
    // }
    .client-logo-listing {
      height: 135px;
      width: 100%;
      overflow: hidden;
      position: relative;
      z-index: 1;
      margin-top: 3rem;
      .brands-marquee {
        padding: 25px 0;
      }
      .js-marquee-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        animation: 140s linear 0s infinite normal none running
          marqueeAnimation-3709221;
        margin-left: 0px;
        width: 18395px;
        transform: translateX(0px);
        &:hover {
          animation: 140s linear 0s infinite normal none paused
            marqueeAnimation-3709221 !important;
        }
      }
      ul {
        display: flex;
        align-items: center;
        height: 100%;
        li {
          padding: 0px 50px;
          display: block;
        }
      }
    }
    .svg-sprite {
      background-image: url(../images/clients/client-logos-sprites.png);
      background-repeat: no-repeat;
      display: block;
      height: 100px;
      &.logo-brand-black-oracle {
        background-position: 0px 0px;
        width: 170px;
      }

      &.logo-brand-black-mtn {
        background-position: -780px 0px;
        width: 205px;
      }

      // &.logo-brand-black-mtu {
      //   background-position: -515px 0px;
      //   width: 180px;
      // }

      // &.logo-brand-black-bcg {
      //   background-position: -1065px 0px;
      //   width: 195px;
      // }

      &.logo-brand-black-sanchobbdo {
        background-position: -1355px 0px;
        width: 190px;
      }

      &.logo-brand-black-gulfstream {
        background-position: -1620px 0px;
        width: 260px;
      }

      &.logo-brand-black-daman {
        background-position: -1955px 0px;
        width: 250px;
      }

      &.logo-brand-black-astrazeneca {
        background-position: -2280px 0px;
        width: 290px;
      }

      &.logo-brand-black-vfsglobal {
        background-position: -2645px 0px;
        width: 205px;
      }

      &.logo-brand-black-haas {
        background-position: -2935px 0px;
        width: 200px;
      }

      &.logo-brand-black-tata {
        background-position: -3225px 0px;
        width: 175px;
      }

      &.logo-brand-black-yahoo {
        background-position: -3490px 0px;
        width: 170px;
      }

      &.logo-brand-black-smithfield {
        background-position: -3745px 0px;
        width: 100px;
      }

      &.logo-brand-black-best-buy {
        background-position: -3920px 0px;
        width: 80px;
      }

      &.logo-brand-black-essilor {
        background-position: -4085px 0px;
        width: 175px;
      }

      &.logo-brand-black-cosmopolitan {
        background-position: -4340px 0px;
        width: 110px;
      }

      &.logo-brand-black-abbott {
        background-position: -4535px 0px;
        width: 170px;
      }

      &.logo-brand-black-unsw {
        background-position: 0px -85px;
        width: 175px;
      }

      &.logo-brand-black-lifetechnologies {
        background-position: -220px -85px;
        width: 70px;
      }

      &.logo-brand-black-jacksoncoker {
        background-position: -385px -95px;
        width: 115px;
      }

      &.logo-brand-black-mcdonalds {
        background-position: -595px -85px;
        width: 200px;
      }

      &.logo-brand-black-vodafone {
        background-position: -885px -85px;
        width: 65px;
      }

      &.logo-brand-black-adidas {
        background-position: -1045px -85px;
        width: 185px;
      }

      &.logo-brand-black-ndtv {
        background-position: -1320px -85px;
        width: 150px;
      }

      &.logo-brand-black-kotak {
        background-position: -1560px -75px;
        width: 240px;
      }

      &.logo-brand-black-macys {
        background-position: -1890px -80px;
        width: 115px;
      }

      &.logo-brand-black-cartier {
        background-position: -2100px -85px;
        width: 185px;
      }
      &.svg-sprite.logo-cambridge-v3 {
        background-position: -260px 0;
        width: 160px;
      }
      &.abdul-latif-jameel-logo {
        background-position: -515px 0;
        width: 180px;
      }
      &.abdul-latif-jameel-logo-001 {
        background-position: -1077px 0;
        width: 200px;
      }
      &.logo-brand-black-Logo1 {
        background-position: -2365px -85px;
        width: 80px;
      }
      &.logo-brand-black-Logo2 {
        background-position: -2535px -85px;
        width: 110px;
      }
      &.logo-brand-black-Logo3 {
        background-position: -2730px -85px;
        width: 125px;
      }
      &.logo-brand-black-Logo4 {
        background-position: -2940px -90px;
        width: 165px;
      }
      &.logo-brand-black-Logo5 {
        background-position: -3190px -90px;
        width: 135px;
      }
      &.logo-brand-black-Logo6 {
        background-position: -3420px -90px;
        width: 225px;
      }
      &.logo-brand-black-Logo7 {
        background-position: -3730px -95px;
        width: 190px;
      }
      &.logo-brand-black-Logo8 {
        background-position: -4020px -95px;
        width: 110px;
      }
      &.logo-brand-black-Logo9 {
        background-position: -4230px -95px;
        width: 180px;
      }
      &.logo-brand-black-Logo10 {
        background-position: -4510px -90px;
        width: 215px;
      }
      &.logo-brand-black-Logo11 {
        background-position: -0px -185px;
        width: 200px;
      }
      &.logo-brand-black-Logo12 {
        background-position: -295px -185px;
        width: 200px;
      }
      &.logo-brand-black-Logo13 {
        background-position: -555px -170px;
        width: 135px;
      }
      &.logo-brand-black-Logo14 {
        background-position: -765px -170px;
        width: 225px;
      }
      &.logo-brand-black-Logo15 {
        background-position: -1075px -175px;
        width: 185px;
      }
      &.logo-brand-black-Logo16 {
        background-position: -1335px -175px;
        width: 165px;
      }
      &.logo-brand-black-Logo17 {
        background-position: -1575px -175px;
        width: 260px;
      }
      &.logo-brand-black-Logo18 {
        background-position: -1910px -175px;
        width: 245px;
      }
      &.logo-brand-black-Logo19 {
        background-position: -2215px -175px;
        width: 220px;
      }
      &.logo-brand-black-Logo20 {
        background-position: -2510px -175px;
        width: 110px;
      }
      &.logo-brand-black-Logo21 {
        background-position: -2700px -175px;
        width: 70px;
      }
      &.logo-brand-black-Logo22 {
        background-position: -2860px -175px;
        width: 150px;
      }
      &.logo-brand-black-Logo23 {
        background-position: -3070px -175px;
        width: 255px;
      }
      &.logo-brand-black-Logo24 {
        background-position: -3400px -175px;
        width: 90px;
      }
      &.logo-brand-black-Logo25 {
        background-position: -3580px -175px;
        width: 75px;
      }
      &.logo-brand-black-Logo26 {
        background-position: -3735px -175px;
        width: 245px;
      }
      &.logo-brand-black-Logo27 {
        background-position: -4065px -175px;
        width: 265px;
      }
      &.logo-brand-black-Logo28 {
        background-position: -4390px -175px;
        width: 290px;
      }
      &.logo-brand-black-Logo29 {
        background-position: 0px -265px;
        width: 250px;
      }
      &.logo-brand-black-Logo30 {
        background-position: -325px -265px;
        width: 235px;
      }
      &.logo-brand-black-Logo31 {
        background-position: -630px -265px;
        width: 145px;
      }
      &.logo-brand-black-Logo32 {
        background-position: -855px -265px;
        width: 150px;
      }
      &.logo-brand-black-Logo33 {
        background-position: -1075px -265px;
        width: 170px;
      }
      &.logo-brand-black-Logo34 {
        background-position: -1320px -265px;
        width: 140px;
      }
      &.logo-brand-black-Logo35 {
        background-position: -1540px -265px;
        width: 130px;
      }
      &.logo-brand-black-Logo36 {
        background-position: -1750px -265px;
        width: 225px;
      }
      &.logo-brand-black-Logo37 {
        background-position: -2035px -265px;
        width: 180px;
      }
      &.logo-brand-black-Logo38 {
        background-position: -2295px -265px;
        width: 200px;
      }
      &.logo-brand-black-Logo39 {
        background-position: -2580px -265px;
        width: 75px;
      }
      &.logo-brand-black-Logo40 {
        background-position: -2750px -265px;
        width: 80px;
      }
      &.logo-brand-black-Logo41 {
        background-position: -2900px -265px;
        width: 135px;
      }
      &.logo-brand-black-Logo42 {
        background-position: -3120px -265px;
        width: 180px;
      }
      &.logo-brand-black-Logo43 {
        background-position: -3370px -265px;
        width: 265px;
      }
      &.logo-brand-black-Logo44 {
        background-position: -3705px -265px;
        width: 150px;
      }
      &.logo-brand-black-Logo45 {
        background-position: -3935px -265px;
        width: 125px;
      }
    }
    @keyframes marqueeAnimation-3709221 {
      100% {
        transform: translateX(-100%);
      }
    }
  }
}

// Sticky Bar Secgtion CSS start

#stickyHeader {
  &.sticky {
    position: sticky;
    top: 0.3%;
    left: 0;
    width: 100%;
    z-index: 99;
    @include md-tablet {
      position: relative;
    }
  }
  .stickyBoxWrap {
    @include centerelm;
    padding: 1.5rem 4rem;
    border-color: #efefef;
    border-width: 0 0.1rem 0.1rem 0.1rem;
    border-style: solid;
    // box-shadow: 0 2rem 3rem 0 #efefef;
    border-radius: 2rem;
    background-color: $white-color;
    @include md-tablet{
      padding: 2rem 2rem;
    }
    @include x-767-tablet {
      flex-direction: column;
      gap: 4rem;
    }
    .custom_width_8 {
      width: $custom_width_8;
      @include md-tablet{
        width: 12%;
      }
      @include x-767-tablet {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .profileImgWrap {
        border-radius: 50%;
        max-width: 6.2rem;
        overflow: hidden;
      }
    }
    .custom_width_20 {
      width: $custom_width_20;
      @include x-767-tablet {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .profileDataWrap {
        @include x-767-tablet {
          text-align: center;
        }
        h6 {
          font-size: 2rem;
          color: $black-color;
          font-weight: $fw-500;
        }
        p {
          font-size: 1.4rem;
          color: $black-color;
          line-height: 2.4rem;
          margin-bottom: 0;
        }
      }
    }
    .custom_width_46 {
      width: $custom_width_46;
      @include x-767-tablet {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .barTitle {
        h5 {
          font-size: 2.6rem;
          font-weight: $fw-600;
          @include x-767-tablet {
            font-size: 2rem;
            text-align: center;
          }
        }
      }
    }
    .custom_width_26 {
      width: $custom_width_26;
      @include x-767-tablet {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .barBtn {
        @include x-767-tablet {
          width: 100%;
        }
        .btn {
          &.orgBtn {
            @include centerelm;
            @include greenButton;
            background: #feefe7;
            gap: 1.5rem;

            .fa-phone {
              color: $orange-color;
              font-size: 2.4rem;
              @include x-767-tablet {
                font-size: 1.6rem;
              }
            }
            span {
              color: $orange-color;
              font-size: $ctaText;
              @include md-tablet {
                font-size: 1.4rem;
              }
              @include x-767-tablet {
                font-size: 1.6rem;
              }
            }
            &:hover {
              .fa-phone {
                color: $white-color;
              }
              span {
                color: $white-color;
              }
            }
          }
        }
      }
    }
  }
}

// Organization Section Start

#organisationSection {
  padding: 14rem 0 10rem;
  @include md-tablet {
    padding: 10rem 0 10rem;
  }
  @include x-767-tablet {
    padding: 0rem 0 6rem;
  }
  .sectionTitle {
    h3 {
      font-size: $sectionTitle;
      font-weight: $fw-500;
      margin-top: 2rem;
      margin-bottom: 8rem;
      text-align: center;
      @include x-767-tablet {
        font-size: 3.4rem;
        margin-bottom: 3rem;
      }
    }
    &.orgOtherPage {
      margin-bottom: 8rem;
      @include x-767-tablet {
        padding: 0 2rem;
        margin-bottom: 3rem;
      }
      .row {
        justify-content: space-between;
        @include d-desktop {
          .col-md-6 {
            width: 60%;
          }
          .col-md-3 {
            width: 35%;
          }
        }
        @include md-tablet {
          .col-md-6 {
            width: 100%;
            h3 {
              text-align: center;
            }
          }
          .col-md-3 {
            width: 100%;
            .readMoreBtnWrap {
             
              .btn {
                &.greenBtn {
                  width: 40%;
                  margin: 0 auto;
                  @include x-767-tablet{
                    width: 85%;
                  }
                }
              }
            }
          }
        }
      }
      h3 {
        text-align: left;
        margin-bottom: 0;
        @include x-767-tablet {
          text-align: center;
        }
      }
      .readMoreBtnWrap {
        width: 100%;
        
      }
    }
  }
  .orgCard {
    width: calc(100% / 3);
    @include md-tablet {
      width: 100%;
    }
    @include x-767-tablet {
      width: 100%;
    }
    padding: 0;
    .innerOrgCard {
      padding: 2.4rem;
      .orgImgWrap {
        overflow: hidden;
        width: 100%;
        margin-bottom: 3rem;
        position: relative;
        a {
          &:hover {
            .overlay {
              position: absolute;
              width: 100%;
              height: 140%;
              z-index: 1;
              top: 3rem;
              left: 0;
              background: linear-gradient(
                to top,
                #f86212 0,
                rgba(248, 17, 109, 0) 100%
              );
            }
          }
        }
      }
      .orgLink {
        h3 {
          font-size: 3.1rem;
          font-weight: $fw-500;
          color: $black-color;
          line-height: 1.3;
          padding-bottom: 2rem;
          border-bottom: 0.2rem dotted #eaeaea;
          margin-bottom: 0;
          @include x-767-tablet {
            font-size: 2.6rem;
          }
        }
        &:hover {
          h3 {
            color: $green-color;
          }
        }
      }

      h4 {
        font-size: $sectionTitle;
        font-weight: $fw-700;
        color: $black-color;
        padding-top: 2rem;
        margin-bottom: 0rem;
        @include x-767-tablet {
          font-size: 3.4rem;
        }
      }
      p {
        font-size: 2rem;
        color: $black-color;
        margin-bottom: 0;
      }
    }
  }
  .readMoreBtnWrap {
    width: 22%;
    margin: 6rem auto 0;
    @include d-desktop{
      width: 35%;
    }
    @include greenAnchorBtn;
    @include md-tablet {
      width: 40%;
    }
    @include x-767-tablet {
      width: 85%;
      margin: 2rem auto 0;
    }
  }
}

// Dark Bg
.darkBG {
  background: linear-gradient(270deg, #f4f4f6 0%, #fff 100%);
  padding: 12rem 0;
}

// Why Work Section CSS Start
.darkBG {
  // .row {
  //   justify-content: space-around;
  // }

  .featureWrap {
    padding: 10rem 0 0;
    @include x-767-tablet {
      padding: 0rem 0 0;
      margin-top: -10rem;
    }
    .row {
      row-gap: 7rem;
      @include x-767-tablet {
        row-gap: 3rem;
      }
    }
    .featBoxWrap {
      display: flex;
      gap: 2rem;
      justify-content: center;
      .iconWrap {
        width: 7rem;
        height: 7rem;
        border: 0.2rem solid $orange-color;
        border-radius: 1.3rem;
        @include centerelm;
        img {
          width: 45%;
        }
      }
      .featContentBox {
        width: 70%;
        h5 {
          font-size: $sectionPara;
          font-weight: $fw-600;
          margin-bottom: 1.5rem;
          color: $black-color;
          @include x-767-tablet {
            font-size: 2.2rem;
          }
        }
        p {
          font-size: 2rem;
          font-weight: $fw-400;
          color: $black-color;
          margin-bottom: 0;
        }
      }
    }
  }
  .awardSection {
    padding-top: 12rem;
    @include x-767-tablet {
      padding-top: 6rem;
    }
    h5 {
      font-size: 3.2rem;
      font-weight: $fw-600;
      margin-bottom: 3.5rem;
      color: $black-color;
      text-align: center;
      @include x-767-tablet {
        font-size: 2.8rem;
      }
    }
    .awardBox {
      display: flex;
      align-items: center;
      flex-direction: column;
      @include x-767-tablet {
        margin-bottom: 3rem;
      }
      .awardImgWrap {
        text-align: center;
        max-width: 15rem;
        margin-bottom: 0.5rem;
        @include x-767-tablet {
          max-width: 10rem;
        }
        img {
          &#img1 {
            filter: hue-rotate(172deg) saturate(106%);
          }
          &#img2 {
            filter: saturate(116%) brightness(106%) contrast(106%);
          }
          &#img3 {
            filter: hue-rotate(336deg) contrast(116%);
          }
          &#img4 {
            filter: hue-rotate(216deg) saturate(124%) brightness(104%)
              contrast(106%);
          }
          transition: filter 0.3s ease;
          &:hover {
            &#img1 {
              filter: hue-rotate(0deg) saturate(100%);
            }
            &#img2 {
              filter: saturate(100%) brightness(100%) contrast(100%);
            }
            &#img3 {
              filter: hue-rotate(0deg) contrast(100%);
            }
            &#img4 {
              filter: hue-rotate(0deg) saturate(100%) brightness(100%)
                contrast(100%);
            }
          }
        }
      }
      .awardNameWrap {
        p {
          font-size: 2rem;
          font-weight: $fw-400;
          color: $black-color;
          margin-bottom: 0;
          text-align: center;
          @include x-767-tablet {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}

.leftInner {
  padding: 0 6rem;
  @include x-767-tablet {
    padding: 0 1.5rem;
  }
  .sectionTitle {
    font-size: $sectionTitle;
    font-weight: $fw-500;
    margin-bottom: 3rem;
    text-align: left;
    @include x-767-tablet {
      font-size: 3.4rem;
    }
  }
  .sectionPara {
    font-size: $sectionPara;
    font-weight: $fw-400;
    text-align: left;
    margin-bottom: 3rem;
  }
  .abstractorName {
    display: none;
  }
  .btnWrap {
    width: 45%;
    @include greenAnchorBtn;
    @include d-desktop{
      width: 70%;
    }
    @include x-767-tablet {
      width: 65%;
      margin-bottom: 4rem;
    }
  }
}

.rightInner {
  .imgWraps {
    display: inline-block;
    z-index: 1;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    max-width: 100%;
    line-height: 1;
    border-radius: 0.8rem;
    height: 50rem;
  }
}

// Abstract section CSS Start
.abstractSection {
  padding: 10rem 0 10rem;
  @include x-767-tablet {
    padding: 0rem 0 3rem;
  }
  .leftInner {
    padding: 0 11rem;
    box-sizing: border-box;
    @include d-desktop{
      padding: 0 3rem;
    }
    @include x-767-tablet {
      padding: 0 1.5rem;
      margin-top: -10rem;
    }
    .sectionTitle {
      display: none;
    }
    .sectionPara {
      font-size: 3.2rem;
      font-weight: $fw-600;
      @include x-767-tablet {
        font-size: 2.2rem;
      }
    }
    .abstractorName {
      display: block;
      h5 {
        font-size: 2rem;
        font-weight: $fw-400;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.4rem;
        font-weight: $fw-400;
        margin-bottom: 0;
      }
    }
    .btnWrap {
      display: none;
    }
  }
}

.adviceSection {
  .titleRow {
    justify-content: space-between;
    @include x-767-tablet{
      padding: 0 1rem;
    }
    .sectionTitle {
      h5 {
        font-size: $sectionTitle;
        font-weight: $fw-500;
        margin-bottom: 0;
        line-height: 1.14;
        @include x-767-tablet {
          font-size: 3.4rem;
          line-height: 1.44;
          margin-bottom: 3rem;
        }
      }
    }
    .btnBox {
      height: 100%;
      @include centerelm;
      align-items: flex-end;
      @include greenAnchorBtn;
      .btn {
        &.greenBtn {
          gap: 0;
          width: 90%;
          @include d-desktop{
            width: 100%;
          }
          @include x-767-tablet {
            width: 65%;
          }
        }
      }
    }
  }
  .adviceCardsSection {
    padding-top: 8rem;
    .outerCardWrap {
      @include x-767-tablet {
        margin-bottom: 4rem;
      }
      .innerCardWrap {
        padding: 0 1.5rem;
        .cardImageWrap {
          position: relative;
          overflow: hidden;
          a {
            &:hover {
              .overlay {
                position: absolute;
                width: 100%;
                height: 140%;
                z-index: 1;
                top: 3rem;
                left: 0;
                background: linear-gradient(
                  to top,
                  #f86212 0,
                  rgba(248, 17, 109, 0) 100%
                );
              }
            }
          }
        }
        .cardTitleWrap {
          margin-top: 2.5rem;
          a {
            h3 {
              font-size: 3.2rem;
              color: $black-color;
              @include x-767-tablet {
                font-size: 2.8rem;
              }
            }
            &:hover {
              h3 {
                color: $green-color;
              }
            }
          }
        }
      }
    }
  }
}

.fooFormSection {
  padding: 10rem 0;
  background-color: $black-color;
  @include x-767-tablet {
    padding: 4rem 0;
  }
  .row {
    justify-content: space-between;
    .leftBoxWrap {
      padding-right: 5rem;
      @include md-tablet {
        padding-right: 0;
      }
      @include x-767-tablet {
        padding: 0 2rem;
      }
      .sectionTitle {
        font-size: 7.2rem;
        color: $white-color;
        line-height: 1.2;
        margin-bottom: 2rem;
        @include x-767-tablet {
          font-size: 4.2rem;
        }
      }
      .sectionSubPara {
        font-size: 3rem;
        color: $white-color;
        margin-bottom: 4rem;
        @include x-767-tablet {
          font-size: 2.6rem;
        }
      }
      .divider {
        position: relative;
        &::before {
          content: "";
          width: 20%;
          height: 0.1rem;
          background-color: $light-black-color;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .authMsg {
        font-size: 2.2rem;
        color: $white-color;
        margin-bottom: 4rem;
        padding-top: 4rem;
        font-style: italic;
      }
      .authNameWrap {
        @include x-767-tablet {
          margin-bottom: 4rem;
        }
        .authName {
          font-size: 2rem;
          color: $white-color;
          font-weight: $fw-400;
          margin-bottom: 1rem;
        }
        .authDesignation {
          font-size: 1.4rem;
          color: #cccccc;
          font-weight: $fw-500;
          margin-bottom: 0;
        }
      }
    }
  }
  @include form;
}
// Contact us Page CSS Start
.faqSection {
  background: #f4f4f6;
  padding: 20rem 0 12rem;
  margin-top: -8rem;
  @include x-767-tablet {
    padding: 14rem 0 4rem;
  }
  .sectionTitle {
    h4 {
      font-size: 3.2rem;
      color: $black-color;
      font-weight: $fw-500;
      margin-bottom: 6rem;
      text-align: center;
      @include x-767-tablet {
        margin-bottom: 2rem;
      }
    }
  }
  @include accordion;
  .accordionWrap {
    .accordion {
      @include x-767-tablet {
        width: auto;
      }
      .accordion-item {
        height: 100%;
        .accordion-title {
          font-size: 2rem;
          padding-right: 1.5rem;
        }
        &.open {
          height: 100%;
        }
      }
    }
  }
}

.contactUsSection {
  padding: 18rem 0 0;
  @include x-767-tablet {
    padding: 10rem 1.5rem 0;
  }
  .pad_lr {
    @include x-767-tablet {
      padding: 0;
    }
  }
  .sectionTitle {
    h1 {
      font-size: $bannerTitle;
      color: $black-color;
      font-weight: $fw-500;
      text-align: center;
      margin-bottom: 8rem;
      @include x-767-tablet {
        font-size: 4.2rem;
        margin-bottom: 4rem;
      }
    }
  }
  .contleftBox {
    .imgWrap {
      margin-bottom: 2.5rem;
    }
    .expertData {
      border-bottom: 0.1rem dotted #ccc;
      padding-bottom: 3rem;
      h4 {
        font-size: 2.6rem;
        font-weight: $fw-500;
        color: $black-color;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.9rem;
        color: $light-black-color;
      }
      @include listing;
    }
    .expertQoute {
      padding-top: 3rem;
      @include x-767-tablet {
        margin-bottom: 3rem;
      }
      p {
        font-size: 2.2rem;
        color: $black-color;
        margin-bottom: 3rem;
      }
      h5 {
        font-size: 2rem;
        font-weight: $fw-500;
        color: $black-color;
        margin-bottom: 1rem;
      }
      span {
        font-size: 1.4rem;
        color: $black-color;
        font-weight: $fw-400;
      }
    }
  }
  @include form;
  .formWrap {
    padding: 1rem 3rem 4rem;
    .formInputWrap {
      .inputBox {
        margin-bottom: 2.5rem;
        &:hover {
          border: 0.1rem solid red;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .downArrow {
        position: absolute;
        right: 2rem;
        top: 5.5rem;
        color: $light-black-color;
      }
      label {
        position: relative;
        width: 100%;

        span {
          .tooltipBox {
            opacity: 0;
            position: absolute;
            top: -60px;
            right: 75px;
            background-color: $black-color;
            padding: 1rem 1rem;
            border-radius: 0.6rem;
            pointer-events: none;
            span {
              color: $white-color;
              font-size: 1.8rem;
            }
            &::after {
              content: "";
              width: 2.5rem;
              height: 2.5rem;
              background-color: $black-color;
              position: absolute;
              bottom: -12px;
              left: 20px;
              transform: rotate(45deg);
            }
          }
          i {
            margin-left: 1rem;
            cursor: pointer;
          }
          &:hover {
            .tooltipBox {
              opacity: 1;
            }
          }
        }
      }
      .form-check {
        margin-bottom: 3rem;
        padding-left: 0;
        .form-check-input {
          width: 1.5rem;
          height: 1.5rem;
          margin-top: -1rem;
          margin-right: 0.8rem;
          border-color: $light-black-color;
          cursor: pointer;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &.custom-checkbox {
            display: none;
            + label {
              &::before {
                content: "";
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                border-radius: 0.4rem;
                border: 0.1rem solid $light-black-color;
                background-color: #fff;
                margin-right: 10px;
                vertical-align: middle;
              }
            }
            &:checked {
              + label {
                &::before {
                  border: 0.1rem solid $orange-color;
                }
                &::after {
                  content: "";
                  position: absolute;
                  width: 0.6rem;
                  height: 0.6rem;
                  left: 0.4rem;
                  background: red;
                  border-radius: 0.2rem;
                  top: 55%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
        .form-check-label {
          cursor: pointer;
          width: auto;
        }
      }

      textarea {
        height: 15rem !important;
        padding: 2rem 1rem !important;
      }
      .select {
        width: 100%;
        height: 5.5rem;
        border: 0.1rem solid #d2d2d2;
        border-radius: 1rem;
        padding: 0 1rem;
        font-size: 1.8rem;
        color: $light-black-color;
        appearance: none;
        position: relative;
        margin-bottom: 3rem;
        &:hover {
          border: 0.1rem solid red;
          cursor: pointer;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

// Why Us Page CSS Start
.whyusSection {
  .bannerSection {
    padding: 18rem 0 12rem;
    @include x-767-tablet {
      padding: 12rem 0 6rem;
    }
    .row {
      .col-md-5 {
        @include md-tablet {
          width: 100%;
        }
      }
      .col-md-7 {
        @include md-tablet {
          width: 100%;
        }
      }
    }
    .innerLeftWrap {
      @include x-767-tablet {
        padding: 0 1.5rem;
      }
      @include listing;
      h1 {
        font-size: $bannerTitle;
        color: $black-color;
        line-height: 1;
        font-weight: $fw-500;
        margin-bottom: 3rem;
        @include x-767-tablet {
          font-size: 4.2rem;
        }
      }
      p {
        font-size: $bannerPara;
        color: $black-color;
        font-weight: $fw-400;
        padding-right: 3rem;
        @include x-767-tablet {
          font-size: 2.6rem;
          padding-right: 0;
        }
      }
      .readMoreBtnWrap {
        margin-top: 4rem;
        @include greenAnchorBtn;
        .w-45 {
          width: 45%;
          @include d-desktop {
            width: 75%;
          }
          @include md-tablet {
            width: 45%;
          }
          @include x-767-tablet {
            width: 60%;
          }
        }
      }
      .ulLsit {
        margin-bottom: 3rem;
      }
    }
    .innerRightWrap {
      position: relative;
      padding-right: 7rem;
      @include x-767-tablet {
        padding-right: 0;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: -6rem;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #f4f4f6;
        z-index: -1;
      }
      img {
        position: relative;
      }
      .overlayTextWrap {
        width: 45%;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2.5rem;
        box-shadow: 0 5rem 7rem 0 #ccc;
        @include x-767-tablet {
          width: 100%;
        }
        h5 {
          font-size: 2rem;
          color: $black-color;
          font-weight: $fw-700;
          margin-bottom: 1.2rem;
        }
        p {
          font-size: 2rem;
          color: $black-color;
          font-weight: $fw-400;
          margin-bottom: 1.2rem;
        }
        h6 {
          font-size: 2rem;
          color: $black-color;
          font-weight: $fw-500;
          margin-bottom: 1rem;
        }
        span {
          font-size: 1.4rem;
          color: $black-color;
          font-weight: $fw-400;
        }
      }
    }
  }
  .strategySection {
    padding: 0 0 12rem;
    @include x-767-tablet {
      padding: 0 1.5rem 6rem;
    }
    .row {
      row-gap: 8rem;
      @include x-767-tablet {
        row-gap: 3rem;
      }
    }
    .sectionTitle {
      width: 60%;
      margin: 0 auto 8rem;
      @include d-desktop {
        width: 85%;
      }
      @include x-767-tablet {
        width: 100%;
        margin: 0 auto 3rem;
      }
      h4 {
        font-size: $sectionTitle;
        color: $black-color;
        text-align: center;
        @include x-767-tablet {
          font-size: 3.4rem;
        }
      }
    }
    .readMoreBtnWrap {
      margin-top: 8rem;
      @include x-767-tablet {
        margin-top: 3rem;
      }
      @include greenAnchorBtn;
      .w-25 {
        @include d-desktop {
          width: 40% !important;
        }
        @include x-767-tablet {
          width: 65% !important;
        }
      }
    }
    .strgWrap {
      display: flex;
      gap: 2rem;
      @include x-767-tablet {
        flex-direction: column;
      }
      .iconBox {
        height: 8rem;
        @include centerelm;
        img {
          width: 75%;
        }
      }
      .contentBox {
        h5 {
          font-size: 2.4rem;
          color: $black-color;
          font-weight: $fw-600;
          margin-bottom: 1.5rem;
          &:hover {
            color: $orange-color;
          }
        }
        p {
          font-size: 2rem;
          color: $black-color;
          margin-bottom: 0;
        }
      }
    }
  }
  .webSecuritySection {
    .sectionTitle {
      p {
        width: 65%;
        margin: 0 auto;
        @include x-767-tablet {
          width: 100%;
        }
      }
    }
  }
  .expertTalkSection {
    padding: 12rem 0 12rem;
    @include md-tablet {
      .col-md-6 {
        width: 100%;
      }
      .offset-1 {
        margin-top: 3rem;
        margin-left: 0;
        width: 100%;
      }
    }
    @include x-767-tablet {
      padding: 3rem 1.5rem 6rem;
      .offset-1 {
        margin-left: 0;
      }
    }
    .talkRightBox {
      @include x-767-tablet {
        margin-top: 3rem;
      }
      h5 {
        font-size: 3.2rem;
        color: $black-color;
        font-weight: $fw-500;
        line-height: 1.26;
        margin-bottom: 4rem;
        @include md-tablet {
          text-align: center;
        }
        @include x-767-tablet {
          font-size: 2.6rem;
        }
      }
      h6 {
        font-size: 2rem;
        font-weight: $fw-500;
        color: $black-color;
        margin-bottom: 1rem;
        @include md-tablet {
          text-align: center;
        }
      }
      span {
        font-size: 1.4rem;
        font-weight: $fw-500;
        @include md-tablet {
          text-align: center;
          display: block;
        }
      }
    }
  }
  .darkBG {
    .awardSection {
      padding-top: 0;
    }
  }
  .faqSection {
    padding: 6rem 0 12rem;
    margin-top: 0;
    background-color: $white-color;
    @include x-767-tablet {
      padding: 3rem 0 4rem;
      .sectionTitle {
        h4 {
          margin-bottom: 2rem;
          font-size: 2.6rem;
        }
      }
      .accordion-item {
        height: 100% !important;
        .accordion-title {
          font-size: 1.8rem;
        }
      }
    }
  }
  .chartSection {
    padding: 12rem 0 8rem;
    @include x-767-tablet {
      padding: 6rem 0 0rem;
    }
    .chartRow {
      justify-content: space-between;
      .col-md-5 {
        width: 45%;
        background-color: #f5f5f7;
        padding: 4rem;
        border-radius: 1.2rem;

        .chartCardWrap {
          .chartImgWrap {
            background-color: $white-color;
          }
        }
      }
    }
  }
  #organisationSection {
    padding: 0rem 0 6rem;
    .readMoreBtnWrap {
      @include d-desktop {
        width: 35%;
      }
      @include x-767-tablet{
        width: 75%;
      }
    }
  }
  .absTestimonialSection {
    padding: 8rem 0 6rem;
    @include x-767-tablet {
      padding: 0rem 0 6rem;
    }
    .abstestiWrap {
      text-align: center;
      padding: 0 5rem;
      @include md-tablet {
        padding: 0;
      }
      @include x-767-tablet {
        padding: 0rem 1.5rem 0rem;
      }
      img {
        max-width: 110px;
        margin-bottom: 2rem;
      }
      h5 {
        font-size: 3.2rem;
        color: $black-color;
        line-height: 1.4;
        font-weight: $fw-500;
        margin-bottom: 2rem;
        @include x-767-tablet {
          font-size: 2.6rem;
        }
      }
      .profileImgWrap {
        border-radius: 50%;
        width: 8rem;
        overflow: hidden;
        margin: 3rem auto 1rem;
        height: 8rem;
        img {
          object-fit: cover;
          height: 100%;
          margin-bottom: 0;
        }
      }
      h6 {
        font-size: 2rem;
        color: $black-color;
        font-size: $fw-500;
        margin-bottom: 1rem;
      }
      span {
        font-size: 1.4rem;
        color: $black-color;
        font-weight: $fw-400;
      }
    }
  }
  .fooFormSection {
    width: 60%;
    margin: 0 auto;
    border-radius: 1.2rem;
    @include d-desktop {
      width: 85%;
    }
    @include md-tablet {
      width: 90%;
    }
    @include x-767-tablet {
      width: 100%;
    }
    .row {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .col-md-6 {
        width: 75%;
        @include x-767-tablet {
          width: 100%;
        }
        .leftBoxWrap {
          padding-right: 0;
          .sectionTitle {
            margin-bottom: 5rem;
            text-align: center;
            padding: 0 6rem;
            @include x-767-tablet {
              padding: 0;
            }
          }
          .sectionSubPara {
            display: none;
          }
          .divider {
            display: none;
          }
          .authMsg {
            display: none;
          }
          .authNameWrap {
            display: none;
          }
        }
      }
      .col-md-5 {
        width: 66.66666667%;
      }
    }
  }
}
// Footer Section CSS Start
.footer {
  padding: 5rem 0 0;
  .textRow {
    margin-bottom: 5rem;
    h4 {
      text-align: center;
      font-size: 3.2rem;
      color: $black-color;
      @include x-767-tablet {
        font-size: 2.6rem;
        line-height: 1.4;
      }
    }
  }

  .copyRightRow {
    background-color: $black-color;
    @include x-767-tablet {
      padding: 0 0 1rem;
    }
    .copyRightBox {
      p {
        font-size: 1.6rem;
        color: $white-color;
        padding: 1.5rem 0;
        margin-bottom: 0;
        text-align: center;
        @include x-767-tablet {
          font-size: 1.4rem;
        }
      }
    }
    .social_row {
      height: 100%;
      justify-content: center;
      display: flex;
      ul {
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin-bottom: 0;
        li {
          a {
            i {
              font-size: 2rem;
              color: $white-color;
              @include x-767-tablet {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}

.conetentSection {
  padding: 12rem 0 6rem;
  @include x-767-tablet {
    padding: 6rem 1.5rem 6rem;
  }
  .abtContentWrap {
    p {
      font-size: 2rem;
      color: $black-color;
      line-height: 1.6;
      margin-bottom: 2rem;
      @include x-767-tablet{
        font-size: 1.8rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .abtPageImgWrap {
    border-radius: 1.2rem;
    overflow: hidden;
    height: 58rem;
    margin-bottom: 4rem;
    @include md-tablet{
      margin-top: 4rem;
    }
    @include x-767-tablet {
      height: 100%;
      margin-bottom: 4rem;
      margin-top: 4rem;
    }
  }
}

.webSecuritySection {
  padding: 0 0 6rem;
  .sectionTitle {
    margin-bottom: 6rem;
    @include x-767-tablet {
      margin-bottom: 3rem;
      padding: 0 1.5rem;
    }
    h3 {
      font-size: $sectionTitle;
      color: $black-color;
      line-height: 1.2;
      margin-bottom: 1rem;
      text-align: center;
      @include x-767-tablet {
        font-size: 3.4rem;
      }
    }
    p {
      font-size: 3rem;
      color: $black-color;
      text-align: center;
      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }
  }
  .webSecureCard {
    padding: 0 2rem;
    @include d-desktop{
      width: 50%;
    }
    @include md-tablet {
      margin-bottom: 5rem;
      width: 50%;
    }
    @include x-767-tablet {
      margin-bottom: 3rem;
      width: 100%;
    }
    .webSecureCardWrap {
      border-width: 0 1px 1px 1px;
      border-color: #ebebeb;
      border-style: solid;
      padding: 3rem 2.5rem;
      border-radius: 1.2rem;
      text-align: center;
      box-shadow: 0px 10px 32px -20px #777777;
      height: auto;
      @include md-tablet {
        height: 100%;
      }
      @include x-767-tablet {
        height: auto;
      }
      h5 {
        font-size: $sectionPara;
        font-weight: $fw-600;
        margin-bottom: 1.5rem;
        color: $black-color;
      }
      p {
        font-size: 2rem;
        color: $black-color;
        font-weight: $fw-300;
      }
      .cardImgWrap {
        height: 12rem;
        width: 12rem;
        margin: 4rem auto 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .getQuote {
        &.w-75 {
          width: 90% !important;
        }
      }
    }
  }
}
.darkBG {
  background: $white-color;
  padding: 6rem 0;
  @include x-767-tablet {
    padding: 3rem 0;
  }
}
.socialMediaSection {
  padding: 12rem 0 0;
  @include x-767-tablet {
    padding: 6rem 0 0;
  }
  .sectionTitle {
    margin-bottom: 10rem;
    @include x-767-tablet {
      margin-bottom: 4rem;
    }
    h4 {
      font-size: $sectionTitle;
      font-weight: $fw-500;
      color: $black-color;
      margin-bottom: 5rem;
      text-align: center;
      width: 35%;
      margin: 0 auto;
      @include d-desktop {
        width: 65%;
      }
      @include x-767-tablet {
        width: 100%;
        font-size: 3.4rem;
      }
    }
    p {
      font-size: $sectionPara;
      color: $black-color;
      text-align: center;
      width: 50%;
      margin: 3rem auto auto;
      @include x-767-tablet {
        width: 100%;
      }
    }
  }
  .socialMediaLeftBox {
    @include x-767-tablet {
      display: flex;
      justify-content: center;
    }
    img {
      @include x-767-tablet {
        max-width: 75%;
      }
    }
  }
  @include accordion;
  .accordion-item {
    @include md-tablet {
      height: 100%;
    }
    &.open {
      @include x-767-tablet {
        height: 100% !important;
      }
    }
  }
  .accordion-title {
    flex-direction: row-reverse;
    justify-content: flex-end !important;
    gap: 3rem;
    font-size: 3rem !important;
    @include md-tablet {
      font-size: 2rem !important;
    }
    @include x-767-tablet {
      font-size: 2.4rem !important;
    }
    .icon {
      font-size: 2rem !important;
    }
  }
}
.innervas {
  #ourClients {
    padding: 8rem 0 12rem;
    @include x-767-tablet {
      padding: 3rem 0 2rem;
    }
  }
}

.chartSection {
  padding: 12rem 0 16rem;
  @include md-tablet{
    padding: 0rem 0 8rem;
  }
  @include x-767-tablet {
    padding: 0rem 0 0rem;
  }
  .chartRow {
    justify-content: space-around;
    @include d-desktop {
      .col-lg-5 {
        width: 50%;
      }
    }
    @include x-767-tablet{
      .col-lg-5 {
        width: 100%;
      } 
    }
    .chartCardWrap {
      padding: 0 2rem;
      h4 {
        font-size: 3.2rem;
        font-weight: $fw-600;
        margin-bottom: 1.5rem;
        color: $black-color;
        text-align: center;
      }
      p {
        font-size: 2rem;
        color: $black-color;
        font-weight: $fw-400;
        text-align: center;
        margin-bottom: 6rem;
      }
      .chartImgWrap {
        border-width: 0 1px 1px 1px;
        border-color: #ebebeb;
        border-style: solid;
        border-radius: 1.2rem;
        box-shadow: 0px 10px 32px -20px #777777;
        padding: 3rem 2.5rem;
        @include centerelm;
        height: 25rem;
        @include x-767-tablet {
          margin-bottom: 5rem;
          height: 15rem;
        }
        img {
          max-width: 350px;
          @include d-desktop {
            max-width: 300px;
          }
          @include md-tablet {
            max-width: 210px;
          }
          @include x-767-tablet {
            max-width: 205px;
          }
        }
      }
    }
  }
}

.successCountSection {
  .countRow {
    .countBoxWrap {
      text-align: center;
      @include x-767-tablet {
        margin-bottom: 3rem;
      }
      .boxImgWrap {
        margin-bottom: 1rem;
        img {
          width: 2rem;
        }
      }
      h3 {
        font-size: $bannerTitle;
        color: $black-color;
        font-weight: $fw-500;
        margin-bottom: 0.5rem;
        @include md-tablet {
          font-size: 5.2rem;
        }
        @include x-767-tablet {
          font-size: 3.2rem;
        }
      }
      p {
        font-size: $sectionPara;
        font-weight: $fw-600;
        margin-bottom: 0;
        @include x-767-tablet {
          font-size: 2rem;
        }
      }
    }
  }
}

.ourMethodologySection {
  padding: 12rem 0 0;
  @include x-767-tablet {
    padding: 6rem 2rem 0;
  }
  .methodImgWrap {
    padding: 0 3rem;
    @include md-tablet{
      margin-bottom: 4rem;
    }
    @include x-767-tablet {
      margin-bottom: 3rem;
    }
  }
  .methodparaWrap {
    h5 {
      font-size: 2rem;
      color: $black-color;
      font-weight: $fw-600;
      margin-bottom: 2.5rem;
    }
    p {
      font-size: 2rem;
      color: $black-color;
      font-weight: $fw-400;
      margin-bottom: 2rem;
      line-height: 1.6;
    }
    ul {
      li {
        list-style: disc;
        font-size: 2rem;
        color: $black-color;
        font-weight: $fw-400;
        line-height: 1.6;
      }
    }
  }
}
// Inner Page Banner Section

.innerBannerSection {
  padding: 22rem 0 0;
  position: relative;
  overflow: hidden;
  @include x-767-tablet {
    padding: 14rem 0 0;
  }
  &::before {
    content: "";
    background: #e9fcf2;
    width: 200rem;
    height: 200rem;
    border-radius: 100%;
    position: absolute;
    top: 0%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    @include x-767-tablet {
      height: 107%;
    }
  }
  .bannerTextWrap {
    text-align: center;
    span {
      font-size: 2rem;
      color: $black-color;
      display: block;
      margin-bottom: 1.5rem;
      font-weight: $fw-500;
    }
    h1 {
      font-size: $bannerTitle;
      color: $black-color;
      line-height: 1;
      font-weight: $fw-500;
      padding: 0 6rem;
      margin-bottom: 1.5rem;
      @include md-tablet {
        padding: 0;
      }
      @include x-767-tablet {
        font-size: 3.2rem;
        padding: 0 3rem;
        line-height: 1.2;
      }
    }
    p {
      font-size: 3rem;
      font-weight: $fw-400;
      @include x-767-tablet {
        font-size: 2.6rem;
      }
    }
  }
  .getConsultBtnWrap {
    margin: 3rem 0 8rem;
    .btn {
      &.consultBtn {
        @include greenButton;
        @include centerelm;
        width: 30%;
        margin: 0 auto;
        background: $ctaGradient-orange;
        @include md-tablet {
          width: 50%;
        }
        @include x-767-tablet {
          width: 60%;
        }
        i {
          display: none;
        }
        &:hover {
          background: $ctaGradient-orange-dark;
        }
      }
    }
  }
}

.vaptimgSection {
  padding-top: 8rem;
  .sectionTitle {
    h4 {
      text-align: center;
      font-size: 2.6rem;
      font-weight: $fw-600;
    }
  }
}
